import { useState, Fragment, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link, Navigate, useNavigate } from "react-router-dom";
import Fab from "@mui/material/Fab";
import PriorityHighIcon from "@mui/icons-material/PriorityHigh";
import makeStyles from "@mui/styles/makeStyles";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Sidebar from "../components/Sidebar";
import Box from "@mui/material/Box";
import { API_URL, alertMessage } from "../actions/auth";
import axios from "axios";
import CircularProgress from "@mui/material/CircularProgress";
import moment from "moment";

function Events() {
  const dispatch = useDispatch();
  function createAlert(type, title, message) {
    dispatch(alertMessage(type, title, message));
  }
  const navigate = useNavigate();

  const params = new URLSearchParams(window.location.search);
  const state = useSelector((state) => state);
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [select, setSelect] = useState("EVENTS");

  const handleSelect = (value) => {
    setSelect(value);
  };

  const handleDateChange = (date) => {
    setSelectedDate(date);
  };

  const handlePageSelect = (event) => {
    setSelectedPage(event.currentTarget.value);
  };

  const [selectedPage, setSelectedPage] = useState(
    params.get("tab") ? params.get("tab") : "EVENTS"
  );
  const colorList = [
    "#99d8c9",
    "#9ebcda",
    "#fdbb84",
    "#a6bddb",
    "#bdbdbd",
    "#fa9fb5",
    "#bcbddc",
    "#addd8e",
    "#9ecae1",
    "#a1d99b",
  ];

  const [isLoading, setIsLoading] = useState(true);
  const [eventsList, setEventsList] = useState([]);
  const [importantDatesList, setimportantDatesList] = useState([]);
  const [holidayList, setHolidayList] = useState([]);

  useEffect(() => {
    if (state.auth.isLoading) {
      dispatch({ type: "ENTRY_PAGE", payload: window.location.pathname });
      navigate("/", { replace: true });
    } else {
      getData();
    }
  }, []);

  function getData() {
    var body = {};
    const config = {
      headers: {
        Authorization: state.auth.token,
        "X-CSRFToken": state.auth.csrfToken,
        "Content-Type": "application/json",
      },
    };
    body["institution_id"] = state.auth.user.institution_id;
    body = JSON.stringify(body);

    axios.post(API_URL + "/get_events_list", body, config).then((res) => {
      setEventsList(res.data["events_list"]);
      setHolidayList(res.data["holidays_list"]);
      setimportantDatesList(res.data["important_dates_list"]);
      setIsLoading(false);
    });
  }

  function getContent() {
    if (selectedPage == "EVENTS") {
      return (
        <Grid container spacing={1.5}>
          {eventsList.length == 0 ? (
            <div
              style={{
                marginTop: "200px",
                height: "100px",
                width: "100%",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <div style={{ textAlign: "center" }}>
                <Fab
                  size="large"
                  style={{
                    border: "3px solid #aaa",
                    boxShadow: "none",
                    backgroundColor: "transparent",
                  }}
                  aria-label="add"
                >
                  <PriorityHighIcon
                    style={{
                      color: "grey",
                    }}
                  />
                </Fab>
                <br />
                <h4 style={{ color: "grey" }}>No upcoming events.</h4>
              </div>
            </div>
          ) : (
            ""
          )}
          {eventsList.map((event, index) => (
            <Grid item md={4}>
              <Box
                padding={1}
                borderRadius="5px"
                sx={{ "&:hover": { opacity: "1 !important" } }}
                style={{
                  opacity: moment(event["date"], "DD-MM-YYYY").isBefore(
                    moment()
                  )
                    ? "0.5"
                    : "1",

                  minHeight: "100%",
                  borderRadius: "6px",
                  border: "2px solid #d7ecff",
                  backgroundColor: "white",
                  boxShadow: "none",
                  marginBottom: "5px",
                  height: "100%",
                }}
              >
                <Box style={{ marginLeft: "5px" }}>
                  <div
                    style={{
                      fontFamily: "Lato-Bold",
                      color: "#084594",
                      fontSize: "19px",
                    }}
                  >
                    {event["name"]}
                  </div>
                  <div
                    style={{
                      fontSize: "15px",
                      fontFamily: "Lato-Regular",
                    }}
                  >
                    {moment(event["date"], "DD-MM-YYYY").format(
                      "Do MMMM, YYYY"
                    )}
                  </div>
                  <div
                    style={{
                      fontSize: "15px",
                      fontFamily: "Lato-Bold",
                    }}
                  >
                    {event["venue"]}
                  </div>
                  <div
                    style={{
                      margin: "10px 0px 0px 0px",
                      fontSize: "15px",
                      fontFamily: "Lato-Regular",
                    }}
                  >
                    {event["description"]}
                  </div>
                </Box>
              </Box>
            </Grid>
          ))}
        </Grid>
      );
    } else if (selectedPage == "DATES") {
      return (
        <Fragment>
          <Grid container spacing={1.5}>
            {importantDatesList.length == 0 ? (
              <div
                style={{
                  marginTop: "200px",
                  height: "100px",
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <div style={{ textAlign: "center" }}>
                  <Fab
                    size="large"
                    style={{
                      border: "3px solid #aaa",
                      boxShadow: "none",
                      backgroundColor: "transparent",
                    }}
                    aria-label="add"
                  >
                    <PriorityHighIcon
                      style={{
                        color: "grey",
                      }}
                    />
                  </Fab>
                  <br />
                  <h4 style={{ color: "grey" }}>
                    No upcoming important dates.
                  </h4>
                </div>
              </div>
            ) : (
              ""
            )}
            {importantDatesList.map((date, index) => (
              <Grid item md={3}>
                <Box
                  padding={1}
                  borderRadius="5px"
                  sx={{ "&:hover": { opacity: "1 !important" } }}
                  style={{
                    opacity: moment(date["date"], "DD-MM-YYYY").isBefore(
                      moment()
                    )
                      ? "0.5"
                      : "1",
                    minHeight: "100%",
                    borderRadius: "6px",
                    border: "2px solid #d7ecff",
                    backgroundColor: "white",
                    boxShadow: "none",
                    marginBottom: "5px",
                    height: "100%",
                  }}
                >
                  <div
                    style={{
                      margin: "0px 0px 0px 10px",
                      fontFamily: "Lato-Bold",
                    }}
                  >
                    <b style={{ color: "#084594", fontSize: "18px" }}>
                      {date["name"]}
                    </b>
                    <br></br>
                    <span style={{ fontFamily: "Lato-Regular" }}>
                      {moment(date["date"], "DD-MM-YYYY").format(
                        "Do MMMM, YYYY"
                      )}
                    </span>
                  </div>
                </Box>
              </Grid>
            ))}
          </Grid>
        </Fragment>
      );
    } else if (selectedPage == "HOLIDAYS") {
      return (
        <Grid container spacing={1.5}>
          {holidayList.length == 0 ? (
            <div
              style={{
                marginTop: "200px",
                height: "100px",
                width: "100%",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <div style={{ textAlign: "center" }}>
                <Fab
                  size="large"
                  style={{
                    border: "3px solid #aaa",
                    boxShadow: "none",
                    backgroundColor: "transparent",
                  }}
                  aria-label="add"
                >
                  <PriorityHighIcon
                    style={{
                      color: "grey",
                    }}
                  />
                </Fab>
                <br />
                <h4 style={{ color: "grey" }}>No upcoming holidays.</h4>
              </div>
            </div>
          ) : (
            ""
          )}
          {holidayList.map((holiday, index) => (
            <Grid item md={3}>
              <Box
                borderRadius="5px"
                padding={1}
                sx={{ "&:hover": { opacity: "1 !important" } }}
                style={{
                  opacity: moment(holiday["date"], "DD-MM-YYYY").isBefore(
                    moment()
                  )
                    ? "0.5"
                    : "1",
                  minHeight: "100%",
                  borderRadius: "6px",
                  border: "2px solid #d7ecff",
                  backgroundColor: "white",
                  boxShadow: "none",
                  marginBottom: "5px",
                  height: "100%",
                }}
              >
                <div
                  style={{
                    margin: "0px 0px 0px 10px",
                    fontFamily: "Lato-Bold",
                  }}
                >
                  <b style={{ color: "#084594", fontSize: "18px" }}>
                    {holiday["name"]}
                  </b>
                  <br></br>
                  <span style={{ fontFamily: "Lato-Regular" }}>
                    {moment(holiday["date"], "DD-MM-YYYY").format(
                      "Do MMMM, YYYY"
                    )}
                  </span>
                </div>
              </Box>
            </Grid>
          ))}
        </Grid>
      );
    }
  }

  if (state.auth.refresh == "REFRESH_DATA") {
    getData();
    dispatch({ type: "REFRESH_DATA", payload: null });
  }

  if (!state.auth.isLoading) {
    if (state.auth.isAuthenticated) {
      if (state.auth.user.is_teacher) {
        if (state.auth.expiry >= new Date()) {
          if (isLoading) {
            return (
              <Fragment>
                <Sidebar />
                <div
                  style={{
                    width: "100%",
                    height: "100%",
                    display: "flex",
                    justifyContent: "center",
                    backgroundColor: "white",
                    marginTop: "46vh",
                  }}
                >
                  <CircularProgress />
                </div>
              </Fragment>
            );
          } else {
            return (
              <Fragment>
                <Sidebar
                  tabs={
                    <Grid container spacing={1.5}>
                      <Grid item md={4}>
                        {selectedPage == "EVENTS" ? (
                          <Button
                            fullWidth
                            value={"EVENTS"}
                            onClick={handlePageSelect}
                            p={2}
                            style={{
                              backgroundColor: "#3182bd",
                              color: "white",
                              height: "30px",
                            }}
                            borderRadius={6}
                          >
                            <h3
                              style={{
                                marginTop: "17px",
                                fontSize: "15px",
                              }}
                            >
                              EVENTS
                            </h3>
                          </Button>
                        ) : (
                          <Button
                            fullWidth
                            value={"EVENTS"}
                            onClick={handlePageSelect}
                            p={2}
                            style={{
                              backgroundColor: "#d7ecff",
                              color: "white",
                              height: "30px",
                            }}
                            borderRadius={6}
                          >
                            <h3
                              style={{
                                marginTop: "17px",
                                fontSize: "15px",
                                color: "#084594",
                              }}
                            >
                              EVENTS
                            </h3>
                          </Button>
                        )}
                      </Grid>
                      <Grid item md={4}>
                        {selectedPage == "DATES" ? (
                          <Button
                            fullWidth
                            value="DATES"
                            onClick={handlePageSelect}
                            p={2}
                            style={{
                              backgroundColor: "#3182bd",
                              color: "white",
                              height: "30px",
                            }}
                            borderRadius={6}
                          >
                            <h3
                              style={{
                                marginTop: "17px",
                                fontSize: "15px",
                              }}
                            >
                              DATES
                            </h3>
                          </Button>
                        ) : (
                          <Button
                            fullWidth
                            value="DATES"
                            onClick={handlePageSelect}
                            p={2}
                            style={{
                              backgroundColor: "#d7ecff",
                              color: "white",
                              height: "30px",
                            }}
                            borderRadius={6}
                          >
                            <h3
                              style={{
                                marginTop: "17px",
                                fontSize: "15px",
                                color: "#084594",
                              }}
                            >
                              DATES
                            </h3>
                          </Button>
                        )}
                      </Grid>
                      <Grid item md={4}>
                        {selectedPage == "HOLIDAYS" ? (
                          <Button
                            fullWidth
                            value="HOLIDAYS"
                            onClick={handlePageSelect}
                            p={2}
                            style={{
                              backgroundColor: "#3182bd",
                              color: "white",
                              height: "30px",
                            }}
                            borderRadius={6}
                          >
                            <h3
                              style={{
                                marginTop: "17px",
                                fontSize: "15px",
                              }}
                            >
                              HOLIDAYS
                            </h3>
                          </Button>
                        ) : (
                          <Button
                            fullWidth
                            value="HOLIDAYS"
                            onClick={handlePageSelect}
                            p={2}
                            style={{
                              backgroundColor: "#d7ecff",
                              color: "white",
                              height: "30px",
                            }}
                            borderRadius={6}
                          >
                            <h3
                              style={{
                                marginTop: "17px",
                                fontSize: "15px",
                                color: "#084594",
                              }}
                            >
                              HOLIDAYS
                            </h3>
                          </Button>
                        )}
                      </Grid>
                    </Grid>
                  }
                />
                <div
                  style={{
                    margin: "65px 15px 20px 85px",
                    backgroundColor: "white",
                  }}
                >
                  {getContent()}
                </div>
              </Fragment>
            );
          }
        } else {
          return <Navigate to="/expired" />;
        }
      } else {
        createAlert("ERROR", "Permission Denied.", "Please Login as Student");
        dispatch({
          type: "AUTH_ERROR",
        });
        return <Navigate to="/login" />;
      }
    } else {
      return <Navigate to="/login" />;
    }
  } else {
    return (
      <div
        style={{
          width: "100%",
          height: "100%",
          display: "flex",
          justifyContent: "center",
          backgroundColor: "white",
          marginTop: "46vh",
        }}
      >
        <CircularProgress />
      </div>
    );
  }
}
export default Events;
