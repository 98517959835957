import { useState, Fragment, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Sidebar from "../components/Sidebar";
import Box from "@mui/material/Box";
import { API_URL, alertMessage } from "../actions/auth";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import axios from "axios";
import CircularProgress from "@mui/material/CircularProgress";
import DesktopDatePicker from "@mui/lab/DesktopDatePicker";
import DeleteIcon from "@mui/icons-material/Delete";
import { Link, Navigate, useNavigate } from "react-router-dom";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import TimePicker from "@mui/lab/TimePicker";
import Modal from "@mui/material/Modal";
import Backdrop from "@mui/material/Backdrop";
import Fade from "@mui/material/Fade";
import Fab from "@mui/material/Fab";
import moment from "moment";
import PriorityHighIcon from "@mui/icons-material/PriorityHigh";
import CloseIcon from "@mui/icons-material/Close";
import IconButton from "@mui/material/IconButton";

function makeid(length) {
  var result = "";
  var characters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  var charactersLength = characters.length;
  for (var i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
}

function Meeting() {
  const params = new URLSearchParams(window.location.search);
  const state = useSelector((state) => state);

  const dispatch = useDispatch();
  function createAlert(type, title, message) {
    dispatch(alertMessage(type, title, message));
  }
  const navigate = useNavigate();

  const [selectedDate, setSelectedDate] = useState(new Date());
  const [meetingList, setMeetingList] = useState([]);
  const [meetingName, setMeetingName] = useState("");
  const [meetingStartTime, setMeetingStartTime] = useState(new Date());
  const [meetingEndTime, setMeetingEndTime] = useState(new Date());
  const [meetingLink, setMeetingLink] = useState("");

  const [isLoading, setIsLoading] = useState(true);
  const [noClasses, setNoClasses] = useState(false);

  const [classList, setClassList] = useState([]);

  const [semester, setSemester] = useState("");

  const [classs, setClass] = useState("");

  const [division, setDivision] = useState("");

  function getClassList() {
    var classes = Object.keys(classList[semester]).map((classs, index) => (
      <MenuItem value={classs}>{classs}</MenuItem>
    ));
    return classes;
  }

  function getDivisionList() {
    var classes = Object.keys(classList[semester][classs]).map(
      (division, index) => <MenuItem value={division}>{division}</MenuItem>
    );
    return classes;
  }

  function getSemesterList() {
    var sems = Object.keys(classList).map((sem, index) => (
      <MenuItem value={sem}>{sem}</MenuItem>
    ));

    return sems;
  }

  const handleSemesterChange = (event) => {
    setSemester(event.target.value);
  };

  const handleClassChange = (event) => {
    setClass(event.target.value);
    setDivision(Object.keys(classList[semester][event.target.value])[0]);
  };

  const handleDivisionChange = (event) => {
    setDivision(event.target.value);
  };

  const handleDateChange = (date) => {
    setSelectedDate(date);
  };

  function getData() {
    var body = {};
    const config = {
      headers: {
        Authorization: state.auth.token,
        "X-CSRFToken": state.auth.csrfToken,
        "Content-Type": "application/json",
      },
    };
    body["email"] = state.auth.user.email;
    body = JSON.stringify(body);

    axios.post(API_URL + "/get_meetings", body, config).then((res) => {
      setMeetingList(res.data);
      setIsLoading(false);
    });
  }

  function getTimeInWords(time) {
    var ampm, hour, minutes, fullTime;

    hour = time.getHours();

    if (hour <= 11) {
      ampm = "AM";
    } else {
      ampm = "PM";
    }

    if (hour > 12) {
      hour = hour - 12;
    }

    if (hour == 0) {
      hour = 12;
    }

    minutes = time.getMinutes();

    if (minutes < 10) {
      minutes = "0" + minutes.toString();
    }

    fullTime =
      hour.toString() + ":" + minutes.toString() + " " + ampm.toString();

    return fullTime;
  }

  const [open, setOpen] = useState(false);
  const [open2, setOpen2] = useState(false);
  const [id, setId] = useState("");

  function deleteMeeting() {
    var body = {};
    const config = {
      headers: {
        Authorization: state.auth.token,
        "X-CSRFToken": state.auth.csrfToken,
        "Content-Type": "application/json",
      },
    };
    body["id"] = id;
    body = JSON.stringify(body);

    axios.post(API_URL + "/delete_meeting", body, config).then((res) => {
      getData();
      handleClose();
    });
  }

  function createMeeting() {
    var body = {};
    const config = {
      headers: {
        Authorization: state.auth.token,
        "X-CSRFToken": state.auth.csrfToken,
        "Content-Type": "application/json",
      },
    };
    body["email"] = state.auth.user.email;
    body["class_id"] = classs;
    body["division"] = division;
    body["time_start"] = getTimeInWords(meetingStartTime);
    body["time_end"] = getTimeInWords(meetingEndTime);
    body["meet_link"] = meetingLink;
    body["meeting_name"] = meetingName;
    body["institution_id"] = state.auth.user.institution_id;
    body["meeting_date"] = moment(selectedDate).format("DD-MM-YYYY");

    body = JSON.stringify(body);

    axios
      .post(API_URL + "/create_meet_link", body, config)
      .then((res) => {
        getData();
        handleClose2();
        createAlert("SUCCESS", "Success.", "Meeting added.");
      })
      .catch((err) => {
        createAlert("ERROR", "Error", "An error occured.");
      });
  }

  const handleClose = () => {
    setOpen(false);
  };

  const handleClose2 = () => {
    setOpen2(false);
  };

  useEffect(() => {
    if (state.auth.isLoading) {
      dispatch({ type: "ENTRY_PAGE", payload: window.location.pathname });
      navigate("/", { replace: true });
    } else {
      var classListTemp = JSON.parse(state.auth.user.class_id_list);
      if (Object.keys(classListTemp).length == 0) {
        setNoClasses(true);
        setIsLoading(false);
      } else {
        setClassList(classListTemp);
        setClass(Object.keys(classListTemp[Object.keys(classListTemp)[0]])[0]);
        setDivision(
          Object.keys(
            classListTemp[Object.keys(classListTemp)[0]][
              Object.keys(classListTemp[Object.keys(classListTemp)[0]])[0]
            ]
          )[0]
        );
        setSemester(Object.keys(classListTemp)[0]);
        getData();
      }
    }
  }, []);

  if (!state.auth.isLoading) {
    if (state.auth.isAuthenticated) {
      if (state.auth.user.is_teacher) {
        if (state.auth.expiry >= new Date()) {
          if (isLoading) {
            return (
              <Fragment>
                <Sidebar />
                <div
                  style={{
                    maxWidth: "88vw",
                    marginTop: "80px",
                    marginLeft: "8vw",
                    backgroundColor: "white",
                    height: "100vh",
                  }}
                >
                  <Grid spacing={0} alignItems="center" justifyContent="center">
                    <Box>
                      <CircularProgress />
                    </Box>
                  </Grid>
                </div>
              </Fragment>
            );
          } else {
            return (
              <Fragment>
                <Sidebar />
                <Modal
                  aria-labelledby="transition-modal-title"
                  aria-describedby="transition-modal-description"
                  open={open}
                  onClose={() => setOpen(false)}
                  closeAfterTransition
                  BackdropComponent={Backdrop}
                  BackdropProps={{
                    timeout: 500,
                  }}
                >
                  <Fade in={open}>
                    <div
                      style={{
                        position: "absolute",
                        top: "50%",
                        backgroundColor: "white",
                        left: "50%",
                        transform: "translate(-50%, -50%)",
                        width: 400,
                        padding: "20px",
                      }}
                    >
                      <div style={{ marginTop: "-25px" }}>
                        <div
                          style={{
                            marginTop: "20px",
                            display: "flex",
                            justifyContent: "flex-end",
                          }}
                        >
                          <IconButton size="small">
                            <CloseIcon
                              onClick={() => setOpen(false)}
                              style={{ color: "#3182bd" }}
                            />
                          </IconButton>
                        </div>
                        <h3>Delete this item?</h3>
                        <span>
                          This action cannot be reversed. It will be deleted
                          forever.
                        </span>
                        <br></br>
                        <br></br>
                        <div
                          style={{
                            display: "flex",
                            width: "100%",
                            justifyContent: "flex-end",
                          }}
                        >
                          <Button
                            onClick={() => deleteMeeting()}
                            style={{ height: "30px" }}
                            variant="contained"
                          >
                            Yes
                          </Button>
                          <Button
                            style={{ height: "30px", marginLeft: "10px" }}
                            variant="contained"
                            onClick={() => setOpen(false)}
                          >
                            No
                          </Button>
                        </div>
                      </div>
                    </div>
                  </Fade>
                </Modal>
                <Modal
                  aria-labelledby="transition-modal-title"
                  aria-describedby="transition-modal-description"
                  open={open2}
                  onClose={() => setOpen2(false)}
                  closeAfterTransition
                  BackdropComponent={Backdrop}
                  BackdropProps={{
                    timeout: 500,
                  }}
                >
                  <Fade in={open2}>
                    <div
                      style={{
                        position: "absolute",
                        top: "50%",
                        backgroundColor: "white",
                        left: "50%",
                        transform: "translate(-50%, -50%)",
                        width: 400,
                        padding: "20px",
                      }}
                    >
                      <div style={{ marginTop: "-25px" }}>
                        <div
                          style={{
                            marginTop: "20px",
                            display: "flex",
                            justifyContent: "flex-end",
                          }}
                        >
                          <IconButton size="small">
                            <CloseIcon
                              onClick={() => setOpen2(false)}
                              style={{ color: "#3182bd" }}
                            />
                          </IconButton>
                        </div>
                        <h3>Create Meeting?</h3>
                        <span>
                          This action cannot be reversed. It will send
                          notifications to all student and parents relevant.
                        </span>
                        <br></br>
                        <br></br>
                        <div
                          style={{
                            display: "flex",
                            width: "100%",
                            justifyContent: "flex-end",
                          }}
                        >
                          <Button
                            onClick={() => createMeeting()}
                            style={{ height: "30px" }}
                            variant="contained"
                          >
                            Yes
                          </Button>
                          <Button
                            style={{ height: "30px", marginLeft: "10px" }}
                            variant="contained"
                            onClick={() => setOpen2(false)}
                          >
                            No
                          </Button>
                        </div>
                      </div>
                    </div>
                  </Fade>
                </Modal>
                <div
                  style={{
                    margin: "65px 15px 20px 90px",
                    backgroundColor: "white",
                  }}
                >
                  {noClasses ? (
                    <h3> No classes or subjects appointed.</h3>
                  ) : (
                    <div>
                      <h3 style={{ marginTop: "-5px" }}>Create Meeting Link</h3>
                      <span
                        style={{
                          color: "#3182bd",
                          fontFamily: "Lato-Regular",
                          fontSize: "14px",
                        }}
                      >
                        Semester
                      </span>
                      <Select
                        style={{
                          marginTop: "2px",
                          height: "40px",
                          marginBottom: "5px",
                        }}
                        variant={"outlined"}
                        fullWidth
                        value={semester}
                        onChange={(e) => handleSemesterChange(e)}
                      >
                        {getSemesterList()}
                      </Select>
                      <Grid
                        container
                        spacing={2}
                        style={{ marginTop: "-15px" }}
                      >
                        <Grid item md={2}>
                          <span
                            style={{
                              color: "#3182bd",
                              fontFamily: "Lato-Regular",
                              fontSize: "14px",
                            }}
                          >
                            Class
                          </span>
                          <Select
                            value={classs}
                            onChange={(e) => handleClassChange(e)}
                            style={{ marginTop: "2px", height: "40px" }}
                            fullWidth
                            variant={"outlined"}
                          >
                            {getClassList()}
                          </Select>
                        </Grid>

                        <Grid item md={2}>
                          <span
                            style={{
                              color: "#3182bd",
                              fontFamily: "Lato-Regular",
                              fontSize: "14px",
                            }}
                          >
                            Division
                          </span>
                          <Select
                            value={division}
                            onChange={(e) => handleDivisionChange(e)}
                            style={{ marginTop: "2px", height: "40px" }}
                            fullWidth
                            variant={"outlined"}
                          >
                            {getDivisionList()}
                          </Select>
                        </Grid>

                        <Grid item md={6}>
                          <span
                            style={{
                              color: "#3182bd",
                              fontFamily: "Lato-Regular",
                              fontSize: "14px",
                            }}
                          >
                            Meeting Name
                          </span>
                          <TextField
                            value={meetingName}
                            onChange={(e) => setMeetingName(e.target.value)}
                            fullWidth
                            size="small"
                            placeholder="Meeting Name"
                            variant="outlined"
                            style={{ marginTop: "2px" }}
                          />
                        </Grid>
                        <Grid item md={2}>
                          <span
                            style={{
                              color: "#3182bd",
                              fontFamily: "Lato-Regular",
                              fontSize: "14px",
                            }}
                          >
                            Date
                          </span>

                          <LocalizationProvider dateAdapter={AdapterDateFns}>
                            <DesktopDatePicker
                              inputFormat="dd-MM-yyyy"
                              value={selectedDate}
                              onChange={handleDateChange}
                              renderInput={(params) => (
                                <TextField
                                  style={{
                                    marginTop: "2px",
                                    marginBottom: "5px",
                                  }}
                                  size="small"
                                  fullWidth
                                  {...params}
                                />
                              )}
                            />
                          </LocalizationProvider>
                        </Grid>
                      </Grid>
                      <Grid
                        container
                        alignItems="center"
                        spacing={2}
                        style={{ marginTop: "-25px" }}
                      >
                        <Grid item md={2}>
                          <div
                            style={{
                              marginTop: "15px",
                              color: "#3182bd",
                              fontFamily: "Lato-Regular",
                              fontSize: "14px",
                            }}
                          >
                            Start time
                          </div>
                          <LocalizationProvider dateAdapter={AdapterDateFns}>
                            <TimePicker
                              value={meetingStartTime}
                              onChange={(time) => setMeetingStartTime(time)}
                              renderInput={(params) => (
                                <TextField
                                  style={{
                                    backgroundColor: "white",
                                    marginTop: "2px",
                                    marginBottom: "5px",
                                  }}
                                  fullWidth
                                  size="small"
                                  {...params}
                                />
                              )}
                            />
                          </LocalizationProvider>
                        </Grid>
                        <Grid item md={2}>
                          <div
                            style={{
                              marginTop: "15px",
                              color: "#3182bd",
                              fontFamily: "Lato-Regular",
                              fontSize: "14px",
                            }}
                          >
                            End time
                          </div>

                          <LocalizationProvider dateAdapter={AdapterDateFns}>
                            <TimePicker
                              value={meetingEndTime}
                              onChange={(time) => setMeetingEndTime(time)}
                              renderInput={(params) => (
                                <TextField
                                  style={{
                                    backgroundColor: "white",
                                    marginTop: "2px",
                                    marginBottom: "5px",
                                  }}
                                  fullWidth
                                  size="small"
                                  {...params}
                                />
                              )}
                            />
                          </LocalizationProvider>
                        </Grid>
                        <Grid item md={6}>
                          <div
                            style={{
                              marginTop: "10px",
                              color: "#3182bd",
                              fontFamily: "Lato-Regular",
                              fontSize: "14px",
                            }}
                          >
                            Meeting Link
                          </div>
                          <TextField
                            value={meetingLink}
                            onChange={(e) => setMeetingLink(e.target.value)}
                            style={{
                              fontSize: "20px",
                              color: "#084594",
                              marginTop: "2px",
                            }}
                            fullWidth
                            size="small"
                            placeholder="Paste Meeting Link Here"
                            variant="outlined"
                          />
                        </Grid>
                        <Grid item md={2}>
                          <Button
                            style={{
                              fontSize: "15px",
                              height: "40px",
                              marginTop: "33px",
                              backgroundColor: "#3182bd",
                            }}
                            onClick={() => setOpen2(true)}
                            variant="contained"
                            fullWidth
                          >
                            <h3>Create</h3>
                            <AddCircleOutlineIcon
                              style={{
                                fontSize: "30px",
                                color: "white",
                                margin: "0px 0px 0px 10px",
                              }}
                            />
                          </Button>
                        </Grid>
                      </Grid>
                      <br></br>
                      {meetingList.length == 0 ? (
                        <div
                          style={{
                            marginTop: "200px",
                            height: "100px",
                            width: "100%",
                            display: "flex",
                            justifyContent: "center",
                          }}
                        >
                          <div style={{ textAlign: "center" }}>
                            <Fab
                              size="large"
                              style={{
                                border: "3px solid #aaa",
                                boxShadow: "none",
                                backgroundColor: "transparent",
                              }}
                              aria-label="add"
                            >
                              <PriorityHighIcon
                                style={{
                                  color: "grey",
                                }}
                              />
                            </Fab>
                            <br />
                            <h4 style={{ color: "grey" }}>
                              No upcoming meetings.
                            </h4>
                          </div>
                        </div>
                      ) : (
                        <div>
                          <h3 style={{ marginTop: "-5px" }}>Meetings</h3>
                          <Grid
                            container
                            alignItems="center"
                            p={0}
                            spacing={2}
                            style={{
                              fontFamily: "Bahnschrift",
                              marginTop: "-10px",
                              fontSize: "15px",
                            }}
                          >
                            <Grid item md={1}>
                              <b style={{ marginLeft: "10px" }}> Class</b>
                            </Grid>

                            <Grid item md={2}>
                              <b style={{ marginLeft: "10px" }}> Topic</b>
                            </Grid>
                            <Grid item md={6}>
                              <b> Link</b>
                            </Grid>
                            <Grid item md={1}>
                              <b> Start</b>
                            </Grid>
                            <Grid item md={1}>
                              <b> End</b>
                            </Grid>
                            <Grid item md={1}>
                              <b> Status</b>
                            </Grid>
                          </Grid>
                          <br></br>
                          {meetingList.map((meeting) => (
                            <Box
                              p={2}
                              style={{
                                marginBottom: "20px",
                                width: "100%",
                                minHeight: "20px",
                                borderRadius: "6px",
                                backgroundColor: "white",
                                border: "2px solid #d7ecff",
                                color: "black",
                              }}
                            >
                              <Grid container alignItems="center">
                                <Grid item md={1}>
                                  {meeting["class_id"]} {meeting["division"]}
                                </Grid>

                                <Grid item md={2}>
                                  {meeting["meeting_name"]}
                                </Grid>
                                <Grid item md={6}>
                                  <div
                                    style={{
                                      paddingRight: "20px",
                                      width: "100%",
                                      display: "flex",
                                      justifyContent: "space-between",
                                    }}
                                  >
                                    <b style={{ color: "blue" }}>
                                      <a
                                        style={{
                                          textDecoration: "none",
                                          fontSize: "12px",
                                        }}
                                        href={meeting["meet_link"]}
                                        target="_blank"
                                      >
                                        {meeting["meet_link"]}
                                      </a>
                                    </b>

                                    <DeleteIcon
                                      onClick={() => {
                                        setId(meeting["id"]);
                                        setOpen(true);
                                      }}
                                      style={{
                                        color: "#ff3333",
                                        cursor: "pointer",
                                      }}
                                    />
                                  </div>
                                </Grid>
                                <Grid item md={1}>
                                  {meeting["time_start"]}
                                </Grid>
                                <Grid item md={1}>
                                  {meeting["time_end"]}
                                </Grid>
                                <Grid item md={1}>
                                  {meeting["meeting_finish"] == false ? (
                                    <span
                                      style={{
                                        textAlign: "center",
                                        marginLeft: "15px",
                                      }}
                                    >
                                      Scheduled
                                    </span>
                                  ) : (
                                    <span
                                      style={{
                                        textAlign: "right",
                                        marginLeft: "15px",
                                      }}
                                    >
                                      Finished
                                    </span>
                                  )}
                                </Grid>
                              </Grid>
                            </Box>
                          ))}
                        </div>
                      )}
                    </div>
                  )}
                </div>
              </Fragment>
            );
          }
        } else {
          return <Navigate to="/expired" />;
        }
      } else {
        createAlert("ERROR", "Permission Denied.", "Please Login as Teacher");
        dispatch({
          type: "AUTH_ERROR",
        });
        return <Navigate to="/login" />;
      }
    } else {
      return <Navigate to="/login" />;
    }
  } else {
    return (
      <div
        style={{
          width: "100%",
          height: "100%",
          display: "flex",
          justifyContent: "center",
          backgroundColor: "white",
          marginTop: "46vh",
        }}
      >
        <CircularProgress />
      </div>
    );
  }
}

export default Meeting;
