import React, { useEffect, useState, Fragment } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link, Navigate, useNavigate } from "react-router-dom";

import makeStyles from "@mui/styles/makeStyles";
import Card from "@mui/material/Card";
import CardActionArea from "@mui/material/CardActionArea";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Sidebar from "../components/Sidebar";
import PropTypes from "prop-types";
import LinearProgress from "@mui/material/LinearProgress";
import Box from "@mui/material/Box";
import { API_URL, alertMessage } from "../actions/auth";
import Paper from "@mui/material/Paper";
import Fab from "@mui/material/Fab";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import TextField from "@mui/material/TextField";
import PublishIcon from "@mui/icons-material/Publish";
import axios from "axios";
import CircularProgress from "@mui/material/CircularProgress";
import Fade from "@mui/material/Fade";
import { tr } from "date-fns/locale";

function Support() {
  const dispatch = useDispatch();
  function createAlert(type, title, message) {
    dispatch(alertMessage(type, title, message));
  }

  const state = useSelector((state) => state);
  const [fadeIn, setFadeIn] = useState(false);
  const [fadeOut, setFadeOut] = useState(false);

  useEffect(() => {
    setFadeIn(true);
  }, []);

  const [q1, setQ1] = useState(3);
  const [q2, setQ2] = useState(3);
  const [q3, setQ3] = useState(3);
  const [q4, setQ4] = useState(3);
  const [q5, setQ5] = useState(3);
  const [q6, setQ6] = useState(3);
  const [q7, setQ7] = useState(3);
  const [q8, setQ8] = useState(3);
  const [q9, setQ9] = useState(3);
  const [q10, setQ10] = useState(3);
  const [q11, setQ11] = useState(3);
  const [q12, setQ12] = useState(3);
  const [q13, setQ13] = useState(3);

  const [qd1, setQD1] = useState("");
  const [qd2, setQD2] = useState("");
  const [qd3, setQD3] = useState("");
  const [qd4, setQD4] = useState("");
  const [qd5, setQD5] = useState("");
  const [qd6, setQD6] = useState("");
  const [qd7, setQD7] = useState("");
  const [qd8, setQD8] = useState("");
  const [qd9, setQD9] = useState("");
  const [qd10, setQD10] = useState("");
  const [qd11, setQD11] = useState("");
  const [qd12, setQD12] = useState("");
  const [qd13, setQD13] = useState("");

  const [q, setQ] = useState(1);

  var questions = [
    "",
    "On average, how many times do you use elemzy in a week?",
    "How useful did you find Elemzy?",
    "How easy was it to use Elemzy?",
    "How easy was it to view/add attendance?",
    "How easy was it to view/add notes and assignments?",
    "How easy was it to view/add results? ",
    "How easy was it to view/add exams?",
    "How easy was it to use leave requests?",
    "How easy was it to use holistic ranking?",
    "How easy was it to use complaints?",
    "How easy was it to use messaging?",
    "How easy was it to use events?",
    "Do you have any additional feedback to help us build you the best app possible?",
  ];

  const navigate = useNavigate();

  function sendFeedback() {
    var a = {
      q1: { r: q1, f: qd1 },
      q2: { r: q2, f: qd2 },
      q3: { r: q3, f: qd3 },
      q4: { r: q4, f: qd4 },
      q5: { r: q5, f: qd5 },
      q6: { r: q6, f: qd6 },
      q7: { r: q7, f: qd7 },
      q8: { r: q8, f: qd8 },
      q9: { r: q9, f: qd9 },
      q10: { r: q10, f: qd10 },
      q11: { r: q11, f: qd11 },
      q12: { r: q12, f: qd12 },
      q13: { r: q13, f: qd13 },
    };
    var body = {};
    const config = {
      headers: {
        Authorization: state.auth.token,
        "X-CSRFToken": state.auth.csrfToken,
        "Content-Type": "application/json",
      },
    };

    body["email"] = state.auth.user.email;
    body["feedback"] = a;
    body = JSON.stringify(body);

    axios
      .post(API_URL + "/save_feedback", body, config)
      .then((res) => {
        createAlert("SUCCESS", "Success.", "Feedback submitted");
        setTimeout(() => {
          navigate("/dashboard", { replace: true });
        }, 3000);      })
      .catch((err) => {
        // createAlert("ERROR", "Error", err.response.data);
      });
  }

  function previousQuestion() {
    if (q > 1) {
      setFadeIn(false);
      setTimeout(() => {
        setQ(q - 1);
        setFadeIn(true);
      }, 1000);
    }
  }

  function nextQuestion() {
    if (q < 13) {
      setFadeIn(false);
      setTimeout(() => {
        setQ(q + 1);
        setFadeIn(true);
      }, 1000);
    } else {
      sendFeedback();
    }
  }

  function getTextInput(q) {
    if (q == 1) {
      return <></>;
    } else if (q == 2) {
      return (
        <TextField
          value={qd2}
          style={{
            marginTop: "2px",
            height: "40px",
            marginBottom: "5px",
          }}
          onChange={(e) => setQD2(e.target.value)}
          fullWidth
          placeholder="Description"
          variant="outlined"
          multiline
          rows={4}
        />
      );
    } else if (q == 3) {
      return (
        <TextField
          value={qd3}
          style={{
            marginTop: "2px",
            height: "40px",
            marginBottom: "5px",
          }}
          onChange={(e) => setQD3(e.target.value)}
          fullWidth
          placeholder="Description"
          variant="outlined"
          multiline
          rows={4}
        />
      );
    } else if (q == 4) {
      return (
        <TextField
          value={qd4}
          style={{
            marginTop: "2px",
            height: "40px",
            marginBottom: "5px",
          }}
          onChange={(e) => setQD4(e.target.value)}
          fullWidth
          placeholder="Description"
          variant="outlined"
          multiline
          rows={4}
        />
      );
    } else if (q == 5) {
      return (
        <TextField
          value={qd5}
          style={{
            marginTop: "2px",
            height: "40px",
            marginBottom: "5px",
          }}
          onChange={(e) => setQD5(e.target.value)}
          fullWidth
          placeholder="Description"
          variant="outlined"
          multiline
          rows={4}
        />
      );
    } else if (q == 6) {
      return (
        <TextField
          value={qd6}
          style={{
            marginTop: "2px",
            height: "40px",
            marginBottom: "5px",
          }}
          onChange={(e) => setQD6(e.target.value)}
          fullWidth
          placeholder="Description"
          variant="outlined"
          multiline
          rows={4}
        />
      );
    } else if (q == 7) {
      return (
        <TextField
          value={qd7}
          style={{
            marginTop: "2px",
            height: "40px",
            marginBottom: "5px",
          }}
          onChange={(e) => setQD7(e.target.value)}
          fullWidth
          placeholder="Description"
          variant="outlined"
          multiline
          rows={4}
        />
      );
    } else if (q == 8) {
      return (
        <TextField
          value={qd8}
          style={{
            marginTop: "2px",
            height: "40px",
            marginBottom: "5px",
          }}
          onChange={(e) => setQD8(e.target.value)}
          fullWidth
          placeholder="Description"
          variant="outlined"
          multiline
          rows={4}
        />
      );
    } else if (q == 9) {
      return (
        <TextField
          value={qd9}
          style={{
            marginTop: "2px",
            height: "40px",
            marginBottom: "5px",
          }}
          onChange={(e) => setQD9(e.target.value)}
          fullWidth
          placeholder="Description"
          variant="outlined"
          multiline
          rows={4}
        />
      );
    } else if (q == 10) {
      return (
        <TextField
          value={qd10}
          style={{
            marginTop: "2px",
            height: "40px",
            marginBottom: "5px",
          }}
          onChange={(e) => setQD10(e.target.value)}
          fullWidth
          placeholder="Description"
          variant="outlined"
          multiline
          rows={4}
        />
      );
    } else if (q == 11) {
      return (
        <TextField
          value={qd11}
          style={{
            marginTop: "2px",
            height: "40px",
            marginBottom: "5px",
          }}
          onChange={(e) => setQD11(e.target.value)}
          fullWidth
          placeholder="Description"
          variant="outlined"
          multiline
          rows={4}
        />
      );
    } else if (q == 12) {
      return (
        <TextField
          value={qd12}
          style={{
            marginTop: "2px",
            height: "40px",
            marginBottom: "5px",
          }}
          onChange={(e) => setQD12(e.target.value)}
          fullWidth
          placeholder="Description"
          variant="outlined"
          multiline
          rows={4}
        />
      );
    } else if (q == 13) {
      return (
        <TextField
          value={qd13}
          style={{
            marginTop: "2px",
            height: "40px",
            marginBottom: "5px",
          }}
          onChange={(e) => setQD13(e.target.value)}
          fullWidth
          placeholder="Description"
          variant="outlined"
          multiline
          rows={4}
        />
      );
    }
  }

  function getText1(q) {
    if (q == 1) {
      return <></>;
    } else if (q == 2) {
      return (
        <Box style={{ marginTop: "-20px", marginRight: 25 }}>
          <h4
            style={{
              textAlign: "center",
              fontSize: 15,
              color: "black",
              fontWeight: "bold",
            }}
          >
            NOT
          </h4>
          <div style={{ marginTop: "-20px" }}>
            <h4
              style={{
                textAlign: "center",
                fontSize: 15,
                color: "black",
                fontWeight: "bold",
              }}
            >
              USEFUL
            </h4>
          </div>
        </Box>
      );
    } else if (q == 13) {
      return <></>;
    } else {
      return (
        <Box style={{ marginTop: "-20px", marginRight: 25 }}>
          <h4
            style={{
              textAlign: "center",
              fontSize: 15,
              color: "black",
              fontWeight: "bold",
            }}
          >
            NOT
          </h4>
          <div style={{ marginTop: "-20px" }}>
            <h4
              style={{
                textAlign: "center",
                fontSize: 15,
                color: "black",
                fontWeight: "bold",
              }}
            >
              EASY
            </h4>
          </div>
        </Box>
      );
    }
  }

  function getText2(q) {
    if (q == 1) {
      return <Fragment></Fragment>;
    } else if (q == 2) {
      return (
        <Box style={{ marginTop: "-20px", marginLeft: 25 }}>
          <h4
            style={{
              textAlign: "center",
              fontSize: 15,
              color: "black",
              fontWeight: "bold",
            }}
          >
            VERY
          </h4>
          <div style={{ marginTop: "-20px" }}>
            <h4
              style={{
                textAlign: "center",
                fontSize: 15,
                color: "black",
                fontWeight: "bold",
              }}
            >
              USEFUL
            </h4>
          </div>
        </Box>
      );
    } else if (q == 13) {
      return <Fragment></Fragment>;
    } else {
      return (
        <Box style={{ marginTop: "-20px", marginLeft: 25 }}>
          <h4
            style={{
              textAlign: "center",
              fontSize: 15,
              color: "black",
              fontWeight: "bold",
            }}
          >
            VERY
          </h4>
          <div style={{ marginTop: "-20px" }}>
            <h4
              style={{
                textAlign: "center",
                fontSize: 15,
                color: "black",
                fontWeight: "bold",
              }}
            >
              EASY
            </h4>
          </div>
        </Box>
      );
    }
  }

  function getButtonColor(q, n) {
    if (q == 1) {
      if (q1 == n) {
        return "#3182bd";
      } else {
        return "white";
      }
    } else if (q == 2) {
      if (q2 == n) {
        return "#3182bd";
      } else {
        return "white";
      }
    } else if (q == 3) {
      if (q3 == n) {
        return "#3182bd";
      } else {
        return "white";
      }
    } else if (q == 4) {
      if (q4 == n) {
        return "#3182bd";
      } else {
        return "white";
      }
    } else if (q == 5) {
      if (q5 == n) {
        return "#3182bd";
      } else {
        return "white";
      }
    } else if (q == 6) {
      if (q6 == n) {
        return "#3182bd";
      } else {
        return "white";
      }
    } else if (q == 7) {
      if (q7 == n) {
        return "#3182bd";
      } else {
        return "white";
      }
    } else if (q == 8) {
      if (q8 == n) {
        return "#3182bd";
      } else {
        return "white";
      }
    } else if (q == 9) {
      if (q9 == n) {
        return "#3182bd";
      } else {
        return "white";
      }
    } else if (q == 10) {
      if (q10 == n) {
        return "#3182bd";
      } else {
        return "white";
      }
    } else if (q == 11) {
      if (q11 == n) {
        return "#3182bd";
      } else {
        return "white";
      }
    } else if (q == 12) {
      if (q12 == n) {
        return "#3182bd";
      } else {
        return "white";
      }
    } else if (q == 13) {
      if (q13 == n) {
        return "#3182bd";
      } else {
        return "white";
      }
    }
  }

  function getTextColor(q, n) {
    if (q == 1) {
      if (q1 != n) {
        return "#3182bd";
      } else {
        return "white";
      }
    } else if (q == 2) {
      if (q2 != n) {
        return "#3182bd";
      } else {
        return "white";
      }
    } else if (q == 3) {
      if (q3 != n) {
        return "#3182bd";
      } else {
        return "white";
      }
    } else if (q == 4) {
      if (q4 != n) {
        return "#3182bd";
      } else {
        return "white";
      }
    } else if (q == 5) {
      if (q5 != n) {
        return "#3182bd";
      } else {
        return "white";
      }
    } else if (q == 6) {
      if (q6 != n) {
        return "#3182bd";
      } else {
        return "white";
      }
    } else if (q == 7) {
      if (q7 != n) {
        return "#3182bd";
      } else {
        return "white";
      }
    } else if (q == 8) {
      if (q8 != n) {
        return "#3182bd";
      } else {
        return "white";
      }
    } else if (q == 9) {
      if (q9 != n) {
        return "#3182bd";
      } else {
        return "white";
      }
    } else if (q == 10) {
      if (q10 != n) {
        return "#3182bd";
      } else {
        return "white";
      }
    } else if (q == 11) {
      if (q11 != n) {
        return "#3182bd";
      } else {
        return "white";
      }
    } else if (q == 12) {
      if (q12 != n) {
        return "#3182bd";
      } else {
        return "white";
      }
    } else if (q == 13) {
      if (q13 != n) {
        return "#3182bd";
      } else {
        return "white";
      }
    }
  }

  if (!state.auth.isLoading) {
    if (state.auth.isAuthenticated) {
      if (state.auth.user.is_teacher) {
        if (state.auth.expiry >= new Date()) {
          return (
            <Fragment>
              <Sidebar />

              <div
                style={{
                  margin: "100px 20px 20px 100px",
                  backgroundColor: "white",
                }}
              >
                <div
                  style={{
                    marginTop: "-18px",
                  }}
                >
                  <Box fullWidth style={{ width: "40%" }}>
                    <h1>Feedback For Elemzy</h1>
                    <div style={{ marginTop: "-10px" }}>
                      <h4>
                        Elemzy, being in the beta stage, may contain bugs or
                        errors. We value your feedback to enhance the user
                        experience
                      </h4>
                    </div>
                    <Fade in={fadeIn}>
                      <div>
                        <h2
                          style={{
                            textAlign: "center",
                            fontSize: 18,
                            color: "black",
                            marginTop: 56,
                          }}
                        >
                          {questions[q]}
                        </h2>
                        {q != 13 ? (
                          <Box
                            style={{
                              marginTop: 50,
                              height: 50,
                              flexDirection: "row",
                              display: "flex",
                              justifyContent: "center",
                              marginBottom: 50,
                            }}
                          >
                            {getText1(q)}
                            <Button
                              style={{
                                height: 40,
                                width: 40,
                                borderRadius: 5,
                                backgroundColor: getButtonColor(q, 1),
                              }}
                              onClick={() => {
                                if (q == 1) {
                                  setQ1(1);
                                } else if (q == 2) {
                                  setQ2(1);
                                } else if (q == 3) {
                                  setQ3(1);
                                } else if (q == 4) {
                                  setQ4(1);
                                } else if (q == 5) {
                                  setQ5(1);
                                } else if (q == 6) {
                                  setQ6(1);
                                } else if (q == 7) {
                                  setQ7(1);
                                } else if (q == 8) {
                                  setQ8(1);
                                } else if (q == 9) {
                                  setQ9(1);
                                } else if (q == 10) {
                                  setQ10(1);
                                } else if (q == 11) {
                                  setQ11(1);
                                } else if (q == 12) {
                                  setQ12(1);
                                } else if (q == 13) {
                                  setQ13(1);
                                }
                              }}
                            >
                              <h4
                                style={{
                                  textAlign: "center",
                                  fontSize: 20,
                                  color: getTextColor(q, 1),
                                  fontWeight: "bold",
                                }}
                              >
                                1
                              </h4>
                            </Button>
                            <Button
                              style={{
                                height: 40,
                                width: 40,
                                borderRadius: 5,
                                backgroundColor: getButtonColor(q, 2),
                              }}
                              onClick={() => {
                                if (q == 1) {
                                  setQ1(2);
                                } else if (q == 2) {
                                  setQ2(2);
                                } else if (q == 3) {
                                  setQ3(2);
                                } else if (q == 4) {
                                  setQ4(2);
                                } else if (q == 5) {
                                  setQ5(2);
                                } else if (q == 6) {
                                  setQ6(2);
                                } else if (q == 7) {
                                  setQ7(2);
                                } else if (q == 8) {
                                  setQ8(2);
                                } else if (q == 9) {
                                  setQ9(2);
                                } else if (q == 10) {
                                  setQ10(2);
                                } else if (q == 11) {
                                  setQ11(2);
                                } else if (q == 12) {
                                  setQ12(2);
                                } else if (q == 13) {
                                  setQ13(2);
                                }
                              }}
                            >
                              <h4
                                style={{
                                  textAlign: "center",
                                  fontSize: 20,
                                  color: getTextColor(q, 2),
                                  fontWeight: "bold",
                                }}
                              >
                                2
                              </h4>
                            </Button>
                            <Button
                              style={{
                                height: 40,
                                width: 40,
                                borderRadius: 5,
                                backgroundColor: getButtonColor(q, 3),
                              }}
                              onClick={() => {
                                if (q == 1) {
                                  setQ1(3);
                                } else if (q == 2) {
                                  setQ2(3);
                                } else if (q == 3) {
                                  setQ3(3);
                                } else if (q == 4) {
                                  setQ4(3);
                                } else if (q == 5) {
                                  setQ5(3);
                                } else if (q == 6) {
                                  setQ6(3);
                                } else if (q == 7) {
                                  setQ7(3);
                                } else if (q == 8) {
                                  setQ8(3);
                                } else if (q == 9) {
                                  setQ9(3);
                                } else if (q == 10) {
                                  setQ10(3);
                                } else if (q == 11) {
                                  setQ11(3);
                                } else if (q == 12) {
                                  setQ12(3);
                                } else if (q == 13) {
                                  setQ13(3);
                                }
                              }}
                            >
                              <h4
                                style={{
                                  textAlign: "center",
                                  fontSize: 20,
                                  color: getTextColor(q, 3),
                                  fontWeight: "bold",
                                }}
                              >
                                3
                              </h4>
                            </Button>
                            <Button
                              style={{
                                height: 40,
                                width: 40,
                                borderRadius: 5,
                                backgroundColor: getButtonColor(q, 4),
                              }}
                              onClick={() => {
                                if (q == 1) {
                                  setQ1(4);
                                } else if (q == 2) {
                                  setQ2(4);
                                } else if (q == 3) {
                                  setQ3(4);
                                } else if (q == 4) {
                                  setQ4(4);
                                } else if (q == 5) {
                                  setQ5(4);
                                } else if (q == 6) {
                                  setQ6(4);
                                } else if (q == 7) {
                                  setQ7(4);
                                } else if (q == 8) {
                                  setQ8(4);
                                } else if (q == 9) {
                                  setQ9(4);
                                } else if (q == 10) {
                                  setQ10(4);
                                } else if (q == 11) {
                                  setQ11(4);
                                } else if (q == 12) {
                                  setQ12(4);
                                } else if (q == 13) {
                                  setQ13(4);
                                }
                              }}
                            >
                              <h4
                                style={{
                                  textAlign: "center",
                                  fontSize: 20,
                                  color: getTextColor(q, 4),
                                  fontWeight: "bold",
                                }}
                              >
                                4
                              </h4>
                            </Button>
                            <Button
                              style={{
                                height: 40,
                                width: 40,
                                borderRadius: 5,
                                backgroundColor: getButtonColor(q, 5),
                              }}
                              onClick={() => {
                                if (q == 1) {
                                  setQ1(5);
                                } else if (q == 2) {
                                  setQ2(5);
                                } else if (q == 3) {
                                  setQ3(5);
                                } else if (q == 4) {
                                  setQ4(5);
                                } else if (q == 5) {
                                  setQ5(5);
                                } else if (q == 6) {
                                  setQ6(5);
                                } else if (q == 7) {
                                  setQ7(5);
                                } else if (q == 8) {
                                  setQ8(5);
                                } else if (q == 9) {
                                  setQ9(5);
                                } else if (q == 10) {
                                  setQ10(5);
                                } else if (q == 11) {
                                  setQ11(5);
                                } else if (q == 12) {
                                  setQ12(5);
                                } else if (q == 13) {
                                  setQ13(5);
                                }
                              }}
                            >
                              <h4
                                style={{
                                  textAlign: "center",
                                  fontSize: 20,
                                  color: getTextColor(q, 5),
                                  fontWeight: "bold",
                                }}
                              >
                                5
                              </h4>
                            </Button>
                            {getText2(q)}
                          </Box>
                        ) : (
                          <Fragment></Fragment>
                        )}
                        <Box
                          style={{
                            width: "100%",
                          }}
                        >
                          {getTextInput(q)}
                        </Box>
                      </div>
                    </Fade>
                    <Box
                      style={{
                        flexDirection: "row",
                        justifyContent: "center",
                        display: "flex",
                        marginTop: 125,
                      }}
                    >
                      <Button
                        onClick={() => {
                          previousQuestion();
                        }}
                        style={{
                          width: 140,
                          height: 40,
                          borderRadius: 5,
                          backgroundColor: "#3182bd",
                        }}
                      >
                        <h4
                          style={{
                            color: "white",
                            fontWeight: "bold",
                            textAlign: "center",
                            fontSize: 20,
                          }}
                        >
                          PREVIOUS
                        </h4>
                      </Button>
                      <div style={{ width: 50 }}></div>
                      <Button
                        onClick={() => {
                          nextQuestion();
                        }}
                        style={{
                          width: 140,
                          height: 40,
                          borderRadius: 5,
                          backgroundColor: "#3182bd",
                        }}
                      >
                        <h4
                          style={{
                            color: "white",
                            fontWeight: "bold",
                            textAlign: "center",
                            fontSize: 20,
                          }}
                        >
                          NEXT
                        </h4>
                      </Button>
                    </Box>
                  </Box>
                </div>
              </div>
            </Fragment>
          );
        } else {
          return <Navigate to="/expired" />;
        }
      } else {
        // createAlert("ERROR", "Permission denied.","Please Login as teacher");

        return (
          <Fragment>
            <Navigate to="/login" />
          </Fragment>
        );
      }
    } else {
      return (
        <Fragment>
          <Navigate to="/login" />
        </Fragment>
      );
    }
  } else {
    return (
      <div
        style={{
          width: "100%",
          height: "100%",
          display: "flex",
          justifyContent: "center",
          backgroundColor: "white",
          marginTop: "46vh",
        }}
      >
        <CircularProgress />
      </div>
    );
  }
}

export default Support;
