import { useState, Fragment, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link, Navigate, useNavigate } from "react-router-dom";

import Grid from "@mui/material/Grid";
import Sidebar from "../components/Sidebar";
import Box from "@mui/material/Box";
import { API_URL, alertMessage } from "../actions/auth";
import PublicIcon from "@mui/icons-material/Public";
import { Line } from "react-chartjs-2";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import axios from "axios";
import CircularProgress from "@mui/material/CircularProgress";
import LocalDiningIcon from "@mui/icons-material/LocalDining";
import GavelIcon from "@mui/icons-material/Gavel";
import BallotIcon from "@mui/icons-material/Ballot";
import ComputerIcon from "@mui/icons-material/Computer";
import HistoryIcon from "@mui/icons-material/History";
import MenuBookIcon from "@mui/icons-material/MenuBook";
import DesktopDatePicker from "@mui/lab/DesktopDatePicker";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import CalendarPicker from "@mui/lab/CalendarPicker";
import { Chart } from "chart.js/auto";
import Button from "@mui/material/Button";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import PriorityHighIcon from "@mui/icons-material/PriorityHigh";
import TextField from "@mui/material/TextField";
import Rating from "@mui/material/Rating";
import moment from "moment";
import Fab from "@mui/material/Fab";

const weekday = [
  "Sunday",
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
];

const colorList = [
  "#e5f5f9",
  "#e0ecf4",
  "#fee8c8",
  "#ece7f2",
  "#f0f0f0",
  "#fde0dd",
  "#efedf5",
  "#e5f5e0",
  "#d7ecff",
  "#fee6ce",
];
const colorList2 = [
  "#2ca25f",
  "#8856a7",
  "#e34a33",
  "#1c9099",
  "#636363",
  "#c51b8a",
  "#756bb1",
  "#31a354",
  "#3182bd",
  "#e6550d",
];

const colorList3 = [
  "#99d8c9",
  "#9ebcda",
  "#fdbb84",
  "#a6bddb",
  "#bdbdbd",
  "#fa9fb5",
  "#bcbddc",
  "#addd8e",
  "#9ecae1",
  "#a1d99b",
];

function makeid(length) {
  var result = "";
  var characters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  var charactersLength = characters.length;
  for (var i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
}

function Feedback() {
  const dispatch = useDispatch();
  function createAlert(type, title, message) {
    dispatch(alertMessage(type, title, message));
  }
  const navigate = useNavigate();

  const [selectedDate, setSelectedDate] = useState(new Date());
  const params = new URLSearchParams(window.location.search);

  const [isLoading, setIsLoading] = useState(true);
  const [selectedPage, setSelectedPage] = useState(
    params.get("tab") ? params.get("tab") : "COMPLAINTS"
  );
  const [subject, setSubject] = useState("");
  const [description, setDescription] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleDateChange = (date) => {
    setSelectedDate(date);
  };

  const state = useSelector((state) => state);

  const [complaintList, setComplaintList] = useState([]);
  const [feedbackForm, setFeedbackForm] = useState([]);
  const [random, setRandom] = useState("");

  function setRating(index, qIndex, rating) {
    var newFeedbackForm = feedbackForm;
    newFeedbackForm[index]["feedback"]["ratings"][qIndex]["rating"] = rating;

    setRandom(makeid(4));
    setFeedbackForm(newFeedbackForm);
  }

  function setSuggestion(index, suggestion) {
    var newFeedbackForm = feedbackForm;
    newFeedbackForm[index]["feedback"]["suggestion"] = suggestion;
    setRandom(makeid(4));
    setFeedbackForm(newFeedbackForm);
  }

  function getComplaints() {
    var body = {};
    const config = {
      headers: {
        Authorization: state.auth.token,
        "X-CSRFToken": state.auth.csrfToken,
        "Content-Type": "application/json",
      },
    };
    body["institution_id"] = state.auth.user.institution_id;
    body["email"] = state.auth.user.email;
    body = JSON.stringify(body);
    axios
      .post(API_URL + "/get_complaints_student", body, config)
      .then((res) => {
        setComplaintList(res.data);
        setIsLoading(false);
      });
  }

  function submitFeedback() {
    var body = {};
    const config = {
      headers: {
        Authorization: state.auth.token,
        "X-CSRFToken": state.auth.csrfToken,
        "Content-Type": "application/json",
      },
    };

    for (var i = 0; i < feedbackForm.length; i++) {
      for (var j = 0; j < feedbackForm[i]["feedback"]["ratings"].length; j++) {
        if (
          feedbackForm[i]["feedback"]["ratings"][j][
            Object.keys(feedbackForm[i]["feedback"]["ratings"][j])[0]
          ] == null
        ) {
          return null;
        } else {
          feedbackForm[i]["feedback"]["ratings"][j][
            Object.keys(feedbackForm[i]["feedback"]["ratings"][j])[0]
          ].push(feedbackForm[i]["feedback"]["ratings"][j]["rating"]);
          delete feedbackForm[i]["feedback"]["ratings"][j]["rating"];
        }
      }
      feedbackForm[i]["feedback"]["suggestions"].push(
        feedbackForm[i]["feedback"]["suggestion"]
      );
      delete feedbackForm[i]["feedback"]["suggestion"];
    }

    body["email"] = state.auth.user.email;
    body["feedback"] = feedbackForm;
    body = JSON.stringify(body);
    axios.post(API_URL + "/submit_feedback", body, config).then((res) => {
      getFeedback();
    });
  }

  function getFeedback() {
    var body = {};
    const config = {
      headers: {
        Authorization: state.auth.token,
        "X-CSRFToken": state.auth.csrfToken,
        "Content-Type": "application/json",
      },
    };
    body["institution_id"] = state.auth.user.institution_id;
    body["class_id"] = state.auth.user.class_id;
    body["division"] = state.auth.user.division;
    body["current_semester"] = state.auth.user.current_semester;

    body = JSON.stringify(body);
    axios.post(API_URL + "/get_feedback_form", body, config).then((res) => {
      setFeedbackForm(res.data);
      setIsLoading(false);
    });
  }

  function makeComplaint() {
    setIsSubmitting(true);
    var body = {};
    const config = {
      headers: {
        Authorization: state.auth.token,
        "X-CSRFToken": state.auth.csrfToken,
        "Content-Type": "application/json",
      },
    };
    body["institution_id"] = state.auth.user.institution_id;
    body["email"] = state.auth.user.email;
    body["class_id"] = state.auth.user.firstname;
    body["division"] = state.auth.user.lastname;
    body["name"] = state.auth.user.firstname + " " + state.auth.user.lastname;
    body["complaint_id"] = makeid(5);
    body["subject"] = subject;
    body["description"] = description;
    body["date"] = moment().format("DD-MM-YYYY");

    body = JSON.stringify(body);
    axios
      .post(API_URL + "/add_complaint", body, config)
      .then((res) => {
        getComplaints();
        setIsSubmitting(false);
        setSubject("");
        setDescription("");
        createAlert("SUCCESS", "Success.", "Complaint submitted.");
      })
      .catch((err) => {
        createAlert("ERROR", "Error", "An error occured.");
      });
  }

  useEffect(() => {
    if (state.auth.isLoading) {
      dispatch({ type: "ENTRY_PAGE", payload: window.location.pathname });
      navigate("/", { replace: true });
    } else {
      getComplaints();
      getFeedback();
    }
  }, []);

  if (state.auth.refresh == "REFRESH_DATA") {
    getComplaints();
    getFeedback();
    dispatch({ type: "REFRESH_DATA", payload: null });
  }

  var colorIndex = 0;

  function getContent() {
    if (selectedPage == "COMPLAINTS") {
      return (
        <Fragment>
          <Grid container spacing={2}>
            <Grid item md={8}>
              {complaintList.length == 0 ? (
                <div
                  style={{
                    marginTop: "200px",
                    height: "100px",
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <div style={{ textAlign: "center" }}>
                    <Fab
                      size="large"
                      style={{
                        border: "3px solid #aaa",
                        boxShadow: "none",
                        backgroundColor: "transparent",
                      }}
                      aria-label="add"
                    >
                      <PriorityHighIcon
                        style={{
                          color: "grey",
                        }}
                      />
                    </Fab>
                    <br />
                    <h4 style={{ color: "grey" }}>No complaints submitted.</h4>
                  </div>
                </div>
              ) : (
                complaintList.map((complaint, index) => (
                  <Fragment>
                    <Box
                      p={2}
                      style={{
                        marginBottom: "15px",
                        borderRadius: "15px",
                        border:
                          complaint["status"] == "PENDING"
                            ? "1.5px solid" + "#3182bd"
                            : "none",
                        backgroundColor:
                          complaint["status"] == "PENDING"
                            ? "white"
                            : complaint["status"] == "RESOLVED"
                            ? colorList[0]
                            : colorList[2],
                        color: "black",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItemds: "center",
                        }}
                      >
                        <div style={{ width: "90%" }}>
                          <div
                            style={{
                              marginTop: "-25px",
                            }}
                          >
                            <h3
                              style={{
                                fontFamily: "Lato-Bold",
                                color:
                                  complaint["status"] == "PENDING"
                                    ? "#3182bd"
                                    : complaint["status"] == "RESOLVED"
                                    ? colorList2[0]
                                    : colorList2[2],
                              }}
                            >
                              {complaint["subject"]}
                            </h3>
                          </div>
                          <div
                            style={{
                              marginTop: "-15px",
                              marginBottom: "5px",
                              lineHeight: "18px",
                            }}
                          >
                            {complaint["description"]}
                          </div>
                          {complaint["status"] != "PENDING" ? (
                            <div>
                              <b>Resolution:</b>
                              <i>{" " + complaint["resolution"]}</i>
                            </div>
                          ) : (
                            ""
                          )}
                        </div>

                        <div
                          style={{
                            width: "100px",
                            display: "flex",
                            justifyContent: "center",
                          }}
                        >
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              flexDirection: "column",
                              alignItems: "center",
                            }}
                          >
                            <Box
                              textAlign="center"
                              p={1}
                              style={{
                                height: "35px",
                                width: "35px",
                                borderRadius: "60px",
                                backgroundColor:
                                  complaint["status"] == "PENDING"
                                    ? "#3182bd"
                                    : complaint["status"] == "RESOLVED"
                                    ? colorList2[0]
                                    : colorList2[2],
                                color: "white",
                              }}
                            >
                              {complaint["status"] == "PENDING" ? (
                                <PriorityHighIcon
                                  style={{
                                    fontSize: "30px",
                                    margin: "-5px 0px 0px -5px",
                                  }}
                                />
                              ) : complaint["status"] == "APPROVED" ? (
                                <CheckIcon
                                  style={{
                                    fontSize: "30px",
                                    margin: "-5px 0px 0px -5px",
                                  }}
                                />
                              ) : (
                                <CloseIcon
                                  style={{
                                    fontSize: "30px",
                                    margin: "-5px 0px 0px -5px",
                                  }}
                                />
                              )}
                            </Box>
                            <b
                              style={{
                                marginTop: "5px",
                                color:
                                  complaint["status"] == "PENDING"
                                    ? "#3182bd"
                                    : complaint["status"] == "RESOLVED"
                                    ? colorList2[0]
                                    : colorList2[2],
                              }}
                            >
                              {complaint["status"]}
                            </b>
                          </div>
                        </div>
                      </div>
                    </Box>
                  </Fragment>
                ))
              )}
            </Grid>

            <Grid item md={4} style={{ marginTop: "-23px" }}>
              <h3>Make a complaint</h3>
              <div
                style={{
                  color: "#3182bd",
                  fontFamily: "Lato-Regular",
                  fontSize: "14px",
                  marginTop: "-10px",
                }}
              >
                Class
              </div>
              <TextField
                value={subject}
                style={{ marginTop: "2px", marginBottom: "5px" }}
                onChange={(e) => setSubject(e.target.value)}
                fullWidth
                size="small"
                placeholder="Reason for complaint"
                variant="outlined"
              />
              <span
                style={{
                  color: "#3182bd",
                  fontFamily: "Lato-Regular",
                  fontSize: "14px",
                }}
              >
                Description
              </span>
              <TextField
                value={description}
                style={{ marginTop: "2px" }}
                onChange={(e) => setDescription(e.target.value)}
                fullWidth
                placeholder="Describe your complaint"
                variant="outlined"
                multiline
                rows={4}
              />
              <br></br>
              <br></br>

              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                }}
              >
                <Button
                  style={{
                    fontSize: "15px",
                    height: "35px",
                    marginTop: "-1px",
                    backgroundColor: "#3182bd",
                  }}
                  onClick={() => makeComplaint()}
                  fullWidth
                  variant="contained"
                  color="primary"
                >
                  <h4>SUBMIT COMPLAINT</h4>
                </Button>
                <br />
                {isSubmitting ? (
                  <div
                    style={{
                      marginTop: "60px",
                      width: "100%",
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <CircularProgress />
                    <h3>Submitting...</h3>
                  </div>
                ) : (
                  <span></span>
                )}
              </div>
            </Grid>
          </Grid>
        </Fragment>
      );
    } else {
      return (
        <div style={{ marginTop: "0px", width: "100%" }}>
          <h3>No feedback yet.</h3>
          {feedbackForm.map((teacher, index) => (
            <div>
              <b style={{ fontSize: "22px" }}>
                {teacher["name"]} - {teacher["subjects"].toString()}
              </b>
              <div style={{ marginTop: "10px" }}>
                {teacher["feedback"]["ratings"].map((question, qIndex) => (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    {Object.keys(question)[0]}
                    <div
                      style={{
                        display: "flex",
                      }}
                    >
                      <Rating
                        name="simple-controlled"
                        value={question["rating"]}
                        onChange={(event, newValue) => {
                          setRating(index, qIndex, newValue);
                        }}
                      />
                    </div>
                  </div>
                ))}
              </div>
              <br></br>
              <span>Suggestions for this teacher</span>
              <TextField
                value={feedbackForm[index]["feedback"]["suggestion"]}
                style={{ marginTop: "2px" }}
                onChange={(e) => setSuggestion(index, e.target.value)}
                fullWidth
                placeholder="Describe your suggestions"
                variant="outlined"
                multiline
                rows={4}
              />
            </div>
          ))}
        </div>
      );
    }
  }

  if (!state.auth.isLoading) {
    if (state.auth.isAuthenticated) {
      if (state.auth.user.is_teacher) {
        if (isLoading) {
          return (
            <Fragment>
              <Sidebar />
              <div
                style={{
                  width: "100%",
                  height: "100%",
                  display: "flex",
                  justifyContent: "center",
                  backgroundColor: "white",
                  marginTop: "46vh",
                }}
              >
                <CircularProgress />
              </div>
            </Fragment>
          );
        } else {
          return (
            <Fragment>
              <Sidebar
                tabs={
                  <Grid container spacing={1.5} justifyContent={"flex-end"}>
                    <Grid item md={4}>
                      {selectedPage == "COMPLAINTS" ? (
                        <Button
                          fullWidth
                          value={"COMPLAINTS"}
                          p={2}
                          style={{
                            backgroundColor: "#3182bd",
                            color: "white",
                            height: "30px",
                          }}
                          borderRadius={6}
                        >
                          <h3
                            style={{
                              marginTop: "17px",
                              fontSize: "15px",
                            }}
                          >
                            COMPLAINTS
                          </h3>
                        </Button>
                      ) : (
                        <Button
                          fullWidth
                          value={"COMPLAINTS"}
                          onClick={() => {
                            setSelectedPage("COMPLAINTS");
                          }}
                          p={2}
                          style={{
                            backgroundColor: "#d7ecff",
                            color: "white",
                            height: "30px",
                          }}
                          borderRadius={6}
                        >
                          <h3
                            style={{
                              marginTop: "17px",
                              fontSize: "15px",
                              color: "#084594",
                            }}
                          >
                            COMPLAINTS
                          </h3>
                        </Button>
                      )}
                    </Grid>
                    {/* <Grid item md={4}>
                        {selectedPage == "FEEDBACK" ? (
                          <Button
                            fullWidth
                            value="FEEDBACK"
                            p={2}
                            style={{
                              backgroundColor: "#3182bd",
                              color: "white",
                              height: "30px",
                            }}
                            borderRadius={6}
                          >
                            <h3
                              style={{
                                marginTop: "17px",
                                fontSize: "15px",
                              }}
                            >
                              FEEDBACK
                            </h3>
                          </Button>
                        ) : (
                          <Button
                            fullWidth
                            value="FEEDBACK"
                            onClick={() => {
                              setSelectedPage("FEEDBACK");
                            }}
                            p={2}
                            style={{
                              backgroundColor: "#d7ecff",
                              color: "white",
                              height: "30px",
                            }}
                            borderRadius={6}
                          >
                            <h3
                              style={{
                                marginTop: "17px",
                                fontSize: "15px",
                                color: "#084594",
                              }}
                            >
                              FEEDBACK
                            </h3>
                          </Button>
                        )}
                      </Grid> */}
                  </Grid>
                }
              />
              <div
                style={{
                  margin: "65px 15px 20px 85px",
                  backgroundColor: "white",
                }}
              >
                {getContent()}
              </div>
            </Fragment>
          );
        }
      } else {
        createAlert("ERROR", "Permission Denied.", "Please Login as Teacher");
        dispatch({
          type: "AUTH_ERROR",
        });
        return <Navigate to="/login" />;
      }
    } else {
      return <Navigate to="/login" />;
    }
  } else {
    return (
      <div
        style={{
          width: "100%",
          height: "100%",
          display: "flex",
          justifyContent: "center",
          backgroundColor: "white",
          marginTop: "46vh",
        }}
      >
        <CircularProgress />
      </div>
    );
  }
}

export default Feedback;
