import axios from "axios";
import { w3cwebsocket } from "websocket";
import moment from "moment";

const PRODUCTION = true;
const TEST = true;

export const API_URL = PRODUCTION
  ? TEST
    ? "https://test.elemzy.com"
    : "https://api.elemzy.com"
  : "http://127.0.0.1:8000";

export const WS_URL = PRODUCTION
  ? TEST
    ? "wss://test.elemzy.com"
    : "wss://api.elemzy.com"
  : "ws://127.0.0.1:8000";

// http://127.0.0.1:8000
// ws://127.0.0.1:8000

export const alertMessage = (type, title, message) => {
  return {
    type: "ALERT",
    payload: { type, title, message },
  };
};

export const getNotifications = () => (dispatch, getState) => {
  var state = getState();
  var body = {
    email: state.auth.user.email,
  };
  const config = {
    headers: {
      Authorization: state.auth.token,
      "X-CSRFToken": state.auth.csrfToken,
      "Content-Type": "application/json",
    },
  };
  axios
    .post(API_URL + "/get_user_unseen_notifications", body, config)
    .then((res) => {
      var unseenNotifications = [];

      for (var i = 0; i < res.data.length; i++) {
        if (res.data[i]["seen"] == false) {
          unseenNotifications.push(res.data[i]);
        }
      }

      dispatch({
        type: "UNSEEN_NOTIFICATIONS_LOADED",
        payload: unseenNotifications.reverse(),
      });

      dispatch({
        type: "NOTIFICATIONS_LOADED",
        payload: res.data,
      });
    });
};

export const loadMessages = () => (dispatch, getState) => {
  var state = getState();
  const config = {
    headers: {
      Authorization: state.auth.token,
      "X-CSRFToken": state.auth.csrfToken,
      "Content-Type": "application/json",
    },
  };
  var body = {};
  body["email"] = state.auth.user.email;
  body["institution_id"] = state.auth.user.institution_id;
  body = JSON.stringify(body);

  axios.post(API_URL + "/teacher_messages", body, config).then((res) => {
    var messages = res.data;
    var tempChatsNew = [];
    var tempChatsRead = [];
    for (var i = 0; i < messages.length; i++) {
      if (
        messages[i][Object.keys(messages[i])[0]][state.auth.user.email] &&
        messages[i][Object.keys(messages[i])[0]][state.auth.user.email] != 0
      ) {
        tempChatsNew.push(messages[i]);
      } else {
        tempChatsRead.push(messages[i]);
      }
    }

    var newChats = tempChatsNew.concat(tempChatsRead);

    dispatch({
      type: "MESSAGES_LOADED",
      payload: newChats,
    });
  });
};

export const loadUser = () => (dispatch, getState) => {
  var state = getState();
  dispatch({ type: "LOADING" });
  axios
    .get(API_URL + "/api/auth/user", {
      headers: {
        Authorization: state.auth.token,
      },
    })
    .then((res) => {
      if (res.data.initiated != "APPROVED") {
        dispatch(
          alertMessage(
            "ERROR",
            "Error. Permission Denied.",
            "Your account has not been approved."
          )
        );
        dispatch({
          type: "AUTH_ERROR",
        });
      } else if (res.data.is_teacher) {
        dispatch({
          type: "USER_LOADED",
          payload: res.data,
        });

        axios
          .get(API_URL + "/get_csrf_token", {
            headers: {
              Authorization: state.auth.token,
            },
          })
          .then((res) => {
            dispatch({
              type: "TOKEN_LOADED",
              payload: res.data,
            });
            state = getState();
            const config = {
              headers: {
                Authorization: state.auth.token,
                "X-CSRFToken": state.auth.csrfToken,
                "Content-Type": "application/json",
              },
            };
            const client = new w3cwebsocket(
              WS_URL + "/ws/chat/" + state.auth.user.channel_id + "/"
            );
            dispatch({
              type: "SOCKET_LOADED",
              payload: client,
            });
            var body = {};
            body["email"] = state.auth.user.email;
            body["institution_id"] = state.auth.user.institution_id;
            body = JSON.stringify(body);
            axios
              .post(API_URL + "/get_current_year", body, config)
              .then((res) => {
                dispatch({
                  type: "CURRENT_YEAR",
                  payload: res.data,
                });
              });
            axios
              .post(API_URL + "/get_setup_status", body, config)
              .then((res) => {
                dispatch({
                  type: "SETUP_STATUS",
                  payload: res.data,
                });
              });
            axios
              .post(API_URL + "/get_subscription", body, config)
              .then((res) => {
                dispatch({
                  type: "SUBSCRIPTION",
                  payload: res.data,
                });
              });
            axios
              .post(API_URL + "/teacher_contacts", body, config)
              .then((res) => {
                dispatch({
                  type: "CONTACTS_LOADED",
                  payload: res.data,
                });
                axios
                  .post(API_URL + "/teacher_messages", body, config)
                  .then((res) => {
                    var messages = res.data.sort((a, b) => {
                      return moment(
                        moment(b["updated_at"]).format("MM/DD/YYYY-HH:MM:SS")
                      ).diff(
                        moment(a["updated_at"]).format("MM/DD/YYYY-HH:MM:SS")
                      );
                    });

                    var tempChatsNew = [];
                    var tempChatsRead = [];
                    for (var i = 0; i < messages.length; i++) {
                      if (
                        messages[i][Object.keys(messages[i])[0]][
                          state.auth.user.email
                        ] &&
                        messages[i][Object.keys(messages[i])[0]][
                          state.auth.user.email
                        ] != 0
                      ) {
                        tempChatsNew.push(messages[i]);
                      } else {
                        tempChatsRead.push(messages[i]);
                      }
                    }

                    var newChats = tempChatsNew.concat(tempChatsRead);

                    dispatch({
                      type: "MESSAGES_LOADED",
                      payload: newChats,
                    });
                  })
                  .catch((err) => {
                    console.log(err);
                  });
              })
              .catch((err) => {
                console.log(err);
              });
          })
          .catch((err) => {
            console.log(err);
          });
      } else {
        dispatch(
          alertMessage(
            "ERROR",
            "Error. Permission Denied.",
            "Please login with teacher account."
          )
        );
      }
    })
    .catch((err) => {
      console.log(err);

      dispatch({
        type: "AUTH_ERROR",
      });
    });
};

// LOGIN USER
export const login = (email, password) => (dispatch) => {
  // Headers
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };

  var fireToken = "web";

  // Request Body
  const body = JSON.stringify({ email, password, fireToken });

  return axios
    .post(API_URL + "/api/auth/login", body, config)
    .then((res) => {
      dispatch({
        type: "LOGIN_SUCCESS",
        payload: res.data,
      });

      dispatch(loadUser());
      dispatch(alertMessage("SUCCESS", "Welcome!", "Login success."));
    })
    .catch((err) => {
      dispatch(
        alertMessage(
          "ERROR",
          err.response.status,
          err.response.data.non_field_errors[0]
        )
      );
      dispatch({
        type: "LOGIN_FAIL",
      });
    });
};

export const sendReset = (email) => (dispatch) => {
  // Headers
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };

  // Request Body
  const body = JSON.stringify({ email });

  axios
    .post(API_URL + "/api/password_reset/", body, config)
    .then((res) => {
      dispatch(
        alertMessage(
          "SUCCESS",
          "Email Sent.",
          "Check your email account for password reset link."
        )
      );
    })
    .catch((err) => {
      dispatch(
        alertMessage(
          "ERROR",
          err.response.status,
          Object.values(err.response.data)[0][0]
        )
      );
    });
};

export const passwordReset = (token, password) => (dispatch) => {
  // Headers
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };

  // Request Body
  const body = JSON.stringify({ token, password });

  axios
    .post(API_URL + "/api/password_reset/confirm/", body, config)
    .then((res) => {
      dispatch({
        type: "PASSWORD_RESET",
        payload: res.data,
      });
      dispatch(
        alertMessage("SUCCESS", "Success!", "Password was reset successfully.")
      );
    })
    .catch((err) => {
      var errString = "";
      for (var i = 0; i < Object.keys(err.response.data).length; i++) {
        for (
          var j = 0;
          j < err.response.data[Object.keys(err.response.data)[i]].length;
          j++
        ) {
          errString +=
            err.response.data[Object.keys(err.response.data)[i]][j] + "\n";
        }
      }
      dispatch(alertMessage("ERROR", err.response.status, errString));
    });
};

export const logout = () => (dispatch, getState) => {
  var state = getState();

  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };

  config.headers["Authorization"] = state.auth.token;

  axios
    .post(API_URL + "/api/auth/logout", null, config)
    .then(() => {
      dispatch(
        alertMessage("SUCCESS", "Logout success.", "You have been logged out.")
      );
      dispatch({ type: "LOGOUT_SUCCESS" });
    })
    .catch((err) => {
      dispatch(
        alertMessage("SUCCESS", "Logout success.", "You have been logged out.")
      );
      dispatch({ type: "AUTH_ERROR" });
    });
};
