import React, { useState, Fragment, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import Grid from "@mui/material/Grid";
import Sidebar from "../components/Sidebar";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import Box from "@mui/material/Box";
import { API_URL, alertMessage } from "../actions/auth";
import Button from "@mui/material/Button";
import GetAppIcon from "@mui/icons-material/GetApp";
import TextField from "@mui/material/TextField";
import PublishIcon from "@mui/icons-material/Publish";
import axios from "axios";
import CircularProgress from "@mui/material/CircularProgress";
import Modal from "@mui/material/Modal";
import Backdrop from "@mui/material/Backdrop";
import Fade from "@mui/material/Fade";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import PriorityHighIcon from "@mui/icons-material/PriorityHigh";
import Fab from "@mui/material/Fab";

import DesktopDatePicker from "@mui/lab/DesktopDatePicker";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import { Link, Navigate, useNavigate } from "react-router-dom";
import ButtonBase from "@mui/material/ButtonBase";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import InboxIcon from "@mui/icons-material/MoveToInbox";
import MailIcon from "@mui/icons-material/Mail";
import { tr } from "date-fns/locale";
import DeleteIcon from "@mui/icons-material/Delete";
import moment from "moment";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import IconButton from "@mui/material/IconButton";

const colorList = ["#e5f5e0", "#d7ecff", "#fee6ce"];
const colorList2 = ["#31a354", "#3283c9", "#e6550d"];
const colorList3 = ["#addd8e", "#9ecae1", "#a1d99b"];

const monthNames = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

function makeid(length) {
  var result = "";
  var characters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  var charactersLength = characters.length;
  for (var i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
}

function Classes() {
  const params = new URLSearchParams(window.location.search);
  const state = useSelector((state) => state);
  const dispatch = useDispatch();
  function createAlert(type, title, message) {
    dispatch(alertMessage(type, title, message));
  }
  const navigate = useNavigate();

  const [selectedPage, setSelectedPage] = useState(
    params.get("tab") ? params.get("tab") : "NOTES"
  );

  const handlePageSelect = (event) => {
    setSelectedPage(event.currentTarget.value);
  };
  const [assignmentsPage, setAssignmentsPage] = useState("ASSIGNMENTS");

  const [selectedDate, setSelectedDate] = useState(new Date());
  const [noClasses, setNoClasses] = useState(false);

  const handleDateChange = (date) => {
    setSelectedDate(date);
  };

  useEffect(() => {
    if (state.auth.isLoading) {
      dispatch({ type: "ENTRY_PAGE", payload: window.location.pathname });
      navigate("/", { replace: true });
    } else {
      var classListTemp = JSON.parse(state.auth.user.class_id_list);

      if (Object.keys(classListTemp).length == 0) {
        setIsLoading(false);
        setNoClasses(true);
      } else {
        setClassList(classListTemp);
        var sems = Object.keys(classListTemp);
        console.log(sems);

        setSemester(Object.keys(classListTemp)[0]);
        setClass(Object.keys(classListTemp[Object.keys(classListTemp)[0]])[0]);
        setDivision(
          Object.keys(
            classListTemp[Object.keys(classListTemp)[0]][
              Object.keys(classListTemp[Object.keys(classListTemp)[0]])[0]
            ]
          )[0]
        );
        setSubject(
          Object.keys(
            classListTemp[Object.keys(classListTemp)[0]][
              Object.keys(classListTemp[Object.keys(classListTemp)[0]])[0]
            ][
              Object.keys(
                classListTemp[Object.keys(classListTemp)[0]][
                  Object.keys(classListTemp[Object.keys(classListTemp)[0]])[0]
                ]
              )[0]
            ][0]
          )[0]
        );

        getData(
          Object.keys(classListTemp[Object.keys(classListTemp)[0]])[0],
          Object.keys(
            classListTemp[Object.keys(classListTemp)[0]][
              Object.keys(classListTemp[Object.keys(classListTemp)[0]])[0]
            ]
          )[0]
        );

        getData();
      }
    }
  }, []);

  if (state.auth.refresh == "REFRESH_DATA") {
    getData();
    dispatch({ type: "REFRESH_DATA", payload: null });
  }

  const [leaveRequestList, setLeaveRequestList] = useState([]);
  const [assignmentList, setAssignmentList] = useState([]);
  const [assignmentIndex, setAssignmentIndex] = useState(0);

  const [notesList, setNotesList] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [id, setId] = useState("");
  const [type, setType] = useState("");
  const [selectedFile, setSelectedFile] = useState("");
  const [leaveSubject, setLeaveSubject] = useState("");
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [classList, setClassList] = useState([]);
  const [semester, setSemester] = useState("");
  const [classs, setClass] = useState("");
  const [division, setDivision] = useState("");
  const [subject, setSubject] = useState("");

  function getSemesterList() {
    var sems = Object.keys(classList).map((sem, index) => (
      <MenuItem value={sem}>{sem}</MenuItem>
    ));

    return sems;
  }

  function getClassList() {
    var classes = Object.keys(classList[semester]).map((classs, index) => (
      <MenuItem value={classs}>{classs}</MenuItem>
    ));

    return classes;
  }

  function getDivisionList() {
    var divisions = Object.keys(classList[semester][classs]).map(
      (division, index) => <MenuItem value={division}>{division}</MenuItem>
    );

    return divisions;
  }

  function getSubjectList() {
    var subjects = classList[semester][classs][division].map(
      (subject, index) => (
        <MenuItem value={Object.keys(subject)[0]}>
          {Object.keys(subject)[0]}
        </MenuItem>
      )
    );
    return subjects;
  }

  const [open, setOpen] = useState(false);
  const [open2, setOpen2] = useState(false);
  const [deleteItemClass, setDeleteItemClass] = useState("");
  const [deleteItemDivision, setDeleteItemDivision] = useState("");

  function handleClassChange(e) {
    setClass(e.target.value);
    setDivision(Object.keys(classList[semester][e.target.value])[0]);
    setSubject(
      Object.keys(
        classList[semester][e.target.value][
          Object.keys(classList[semester][e.target.value])[0]
        ][0]
      )[0]
    );
  }

  function handleDivisionChange(e) {
    setDivision(e.target.value);
    setSubject(
      Object.keys(classList[semester][classs][[e.target.value]][0])[0]
    );
  }

  function handleSemesterChange(e) {
    setSemester(e.target.value);
    setClass(Object.keys(classList[e.target.value])[0]);
    setDivision(
      Object.keys(
        classList[e.target.value][Object.keys(classList[e.target.value])[0]]
      )[0]
    );
    setSubject(
      Object.keys(
        classList[e.target.value][Object.keys(classList[e.target.value])[0]][
          Object.keys(
            classList[e.target.value][Object.keys(classList[e.target.value])[0]]
          )[0]
        ][0]
      )[0]
    );
  }

  function handleSubjectChange(e) {
    setSubject(e.target.value);
  }

  function getSize(fileSize) {
    if (fileSize < 1024) {
      return <p>Size: {(fileSize / 1024).toFixed(2)} B</p>;
    } else if (fileSize > 1024 && fileSize < 1024 * 1024) {
      return <p>Size: {(fileSize / (1024 * 1024)).toFixed(2)} KB</p>;
    } else if (fileSize > 1024 * 1024 && fileSize < 25 * 1024 * 1024) {
      return <p>Size: {(fileSize / (1024 * 1024)).toFixed(2)} MB</p>;
    } else if (fileSize > 25 * 1024 * 1024) {
    }
  }

  function fileData() {
    if (selectedFile) {
      return (
        <div>
          <div
            style={{
              marginTop: "20px",
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <IconButton size="small">
              <CloseIcon
                onClick={() => setOpen(false)}
                style={{ color: "#3182bd" }}
              />
            </IconButton>
          </div>
          <h2>File Details:</h2>
          <p>File Name: {selectedFile.name}</p>
          <p>File Type: {selectedFile.type}</p>

          <br></br>
          <br></br>
          {selectedFile.size > 25 * 1024 * 1024 ? (
            <div style={{ display: "flex", justifyContent: "center" }}>
              <Button
                onClick={() => {
                  createAlert(
                    "ERROR",
                    "File too big.",
                    "File size should be less than 25 MB."
                  );
                  setOpen(false);
                }}
                variant="contained"
                style={{ backgroundColor: "#ddd" }}
              >
                <PublishIcon />
                &nbsp;&nbsp; UPLOAD DOCUMENTS
              </Button>
            </div>
          ) : (
            <div style={{ display: "flex", justifyContent: "center" }}>
              <Button
                variant="contained"
                onClick={() => onFileUpload()}
                color="primary"
              >
                <PublishIcon />
                &nbsp;&nbsp; UPLOAD DOCUMENTS
              </Button>
            </div>
          )}
        </div>
      );
    } else {
      return (
        <div>
          <h4>Choose before Pressing the Upload button</h4>
        </div>
      );
    }
  }

  function deleteItem() {
    var body = {};
    const config = {
      headers: {
        Authorization: state.auth.token,
        "X-CSRFToken": state.auth.csrfToken,
        "Content-Type": "application/json",
      },
    };
    if (type == "NOTE") {
      body["class"] = deleteItemClass;
      body["division"] = deleteItemDivision;
      body["email"] = state.auth.user.email;
      body["id"] = id;
      body = JSON.stringify(body);
      axios.post(API_URL + "/delete_note", body, config).then((res) => {
        getData();
        setOpen2(false);
      });
    } else if (type == "ASSIGNMENT") {
      body["class"] = deleteItemClass;
      body["division"] = deleteItemDivision;
      body["email"] = state.auth.user.email;
      body["id"] = id;
      body = JSON.stringify(body);
      axios.post(API_URL + "/delete_assignment", body, config).then((res) => {
        getData();
        setAssignmentsPage("ASSIGNMENTS");
        setOpen2(false);
      });
    } else if (type == "LEAVEREQUEST") {
      body["email"] = state.auth.user.email;
      body["id"] = id;
      body = JSON.stringify(body);
      axios
        .post(API_URL + "/delete_leave_request", body, config)
        .then((res) => {
          getData();
          setOpen2(false);
        });
    }
  }

  function onFileUpload() {
    setIsSubmitting(true);
    // Create an object of formData
    const formData = new FormData();

    // Update the formData object
    formData.append("file", selectedFile, selectedFile.name);

    // Details of the uploaded file
    console.log(selectedFile);

    // Request made to the backend api
    // Send formData object

    const config = {
      headers: {
        Authorization: state.auth.token,
        "X-CSRFToken": state.auth.csrfToken,
        // "Content-Type": "application/json",
      },
    };

    formData.append("name", name);
    formData.append("email", state.auth.user.email);
    formData.append("subject", subject);
    formData.append("description", description);
    formData.append("institution_id", state.auth.user.institution_id);
    formData.append("leave_subject", leaveSubject);
    formData.append("current_year", state.auth.current_year);

    formData.append("id", makeid(5));
    formData.append(
      "completion_date",
      moment(selectedDate).format("DD-MM-YYYY")
    );

    formData.append("date", moment(selectedDate).format("DD-MM-YYYY"));

    formData.append("class_id", classs);
    formData.append("division", division);

    if (selectedPage == "NOTES") {
      axios
        .post(API_URL + "/add_notes", formData, config)
        .then(() => {
          getData();
          setIsSubmitting(false);
          setToggleDrawer(false);
          createAlert("SUCCESS", "Success.", "Note added.");
        })
        .catch((err) => {
          createAlert("ERROR", "Error", "An error occured.");
        });
    } else if (selectedPage == "ASSIGNMENTS") {
      axios
        .post(API_URL + "/add_assignment", formData, config)
        .then(() => {
          getData();
          setIsSubmitting(false);
          setToggleDrawer(false);
          createAlert("SUCCESS", "Success.", "Assignment added.");
        })
        .catch((err) => {
          createAlert("ERROR", "Error", "An error occured.");
        });
    } else if (selectedPage == "LEAVEREQUESTS") {
      axios
        .post(API_URL + "/add_leave_request", formData, config)
        .then(() => {
          getData();
          setIsSubmitting(false);
          setToggleDrawer(false);
          createAlert("SUCCESS", "Success.", "Leave request submitted.");
        })
        .catch((err) => {
          createAlert("ERROR", "Error", "An error occured.");
        });
    }

    setOpen(false);
    document.getElementById("contained-button-file").value = "";
  }

  function onFileChange(event) {
    // Update the state
    setSelectedFile(event.target.files[0]);

    setOpen(true);
  }

  function onFileClick(event) {
    // Update the state
    document.getElementById("contained-button-file").value = "";
  }

  function getData() {
    var body = {};
    const config = {
      headers: {
        Authorization: state.auth.token,
        "X-CSRFToken": state.auth.csrfToken,
        "Content-Type": "application/json",
      },
    };
    body["institution_id"] = state.auth.user.institution_id;
    body["email"] = state.auth.user.email;

    body = JSON.stringify(body);

    axios
      .post(API_URL + "/get_notes_list_teacher", body, config)
      .then((res) => {
        setNotesList(res.data);
        axios
          .post(API_URL + "/get_assignment_list_teacher", body, config)
          .then((res) => {
            setAssignmentList(res.data);
            console.log(res.data);
            axios
              .post(API_URL + "/get_leave_requests", body, config)
              .then((res) => {
                setLeaveRequestList(res.data);
                setIsLoading(false);
              });
          });
      });
  }

  const [toggleDrawer, setToggleDrawer] = useState(false);

  function selectedPageContent() {
    if (selectedPage == "NOTES") {
      if (noClasses) {
        return (
          <div
            style={{
              marginTop: "200px",
              height: "100px",
              width: "100%",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <div style={{ textAlign: "center" }}>
              <Fab
                size="large"
                style={{
                  border: "3px solid #aaa",
                  boxShadow: "none",
                  backgroundColor: "transparent",
                }}
                aria-label="add"
              >
                <PriorityHighIcon
                  style={{
                    color: "grey",
                  }}
                />
              </Fab>
              <br />
              <h4 style={{ color: "grey" }}>
                No classes or subjects appointed.
              </h4>
            </div>
          </div>
        );
      }

      return (
        <Fragment>
          <div>
            <React.Fragment key={"right"}>
              <Fab
                size="large"
                style={{
                  boxShadow: "none",
                  backgroundColor: "#ff3333 ",
                  position: "fixed",
                  bottom: 30,
                  right: 30,
                }}
                onClick={() => setToggleDrawer(true)}
                aria-label="add"
              >
                <PublishIcon
                  style={{
                    color: "white",
                  }}
                />
              </Fab>
              <Drawer
                anchor={"right"}
                open={toggleDrawer}
                onClose={() => setToggleDrawer(false)}
              >
                {isSubmitting ? (
                  <div
                    style={{
                      marginTop: "60px",
                      padding: "20px",
                      width: "400px",
                      backgroundColor: "white",
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      alignItems: "center",
                      height: "100%",
                    }}
                  >
                    <CircularProgress />
                    <h3>Uploading in progress...</h3>
                  </div>
                ) : (
                  <div
                    style={{
                      marginTop: "40px",
                      padding: "20px",
                      width: "400px",
                    }}
                  >
                    <span
                      style={{
                        color: "#3182bd",
                        fontFamily: "Lato-Regular",
                        fontSize: "14px",
                      }}
                    >
                      Semester
                    </span>
                    <Select
                      value={semester}
                      onChange={(e) => handleSemesterChange(e)}
                      style={{
                        marginTop: "2px",
                        height: "40px",
                        marginBottom: "5px",
                      }}
                      fullWidth
                      variant={"outlined"}
                    >
                      {getSemesterList()}
                    </Select>

                    <span
                      style={{
                        color: "#3182bd",
                        fontFamily: "Lato-Regular",
                        fontSize: "14px",
                      }}
                    >
                      Class
                    </span>
                    <Select
                      value={classs}
                      onChange={(e) => handleClassChange(e)}
                      style={{
                        marginTop: "2px",
                        height: "40px",
                        marginBottom: "5px",
                      }}
                      fullWidth
                      variant={"outlined"}
                    >
                      {getClassList()}
                    </Select>

                    <span
                      style={{
                        color: "#3182bd",
                        fontFamily: "Lato-Regular",
                        fontSize: "14px",
                      }}
                    >
                      Division
                    </span>
                    <Select
                      value={division}
                      onChange={(e) => handleDivisionChange(e)}
                      style={{
                        marginTop: "2px",
                        height: "40px",
                        marginBottom: "5px",
                      }}
                      fullWidth
                      variant={"outlined"}
                    >
                      {getDivisionList()}
                    </Select>

                    <span
                      style={{
                        color: "#3182bd",
                        fontFamily: "Lato-Regular",
                        fontSize: "14px",
                      }}
                    >
                      Subject
                    </span>
                    <Select
                      value={subject}
                      onChange={(e) => handleSubjectChange(e)}
                      style={{
                        marginTop: "2px",
                        height: "40px",
                        marginBottom: "5px",
                      }}
                      fullWidth
                      variant={"outlined"}
                    >
                      {getSubjectList()}
                    </Select>

                    <span
                      style={{
                        color: "#3182bd",
                        fontFamily: "Lato-Regular",
                        fontSize: "14px",
                      }}
                    >
                      Name
                    </span>
                    <TextField
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                      fullWidth
                      size="small"
                      style={{
                        marginTop: "2px",
                        height: "40px",
                        marginBottom: "5px",
                      }}
                      variant={"outlined"}
                      placeholder="Note name"
                    />

                    <span
                      style={{
                        color: "#3182bd",
                        fontFamily: "Lato-Regular",
                        fontSize: "14px",
                      }}
                    >
                      Description
                    </span>

                    <TextField
                      value={description}
                      style={{
                        marginTop: "2px",
                        height: "40px",
                        marginBottom: "5px",
                      }}
                      onChange={(e) => setDescription(e.target.value)}
                      fullWidth
                      placeholder="Description"
                      variant="outlined"
                      multiline
                      rows={4}
                    />
                    <br></br>
                    <br></br>
                    <br></br>
                    <br></br>
                    <br></br>

                    <Box
                      style={{
                        width: "100%",
                        justifyContent: "center",
                        display: "flex",
                      }}
                    >
                      <label
                        style={{ width: "100%" }}
                        htmlFor="contained-button-file"
                      >
                        <input
                          style={{ display: "none" }}
                          id="contained-button-file"
                          type="file"
                          onChange={(e) => onFileChange(e)}
                        />

                        <Button
                          style={{
                            width: "100%",
                            fontSize: "15px",
                            height: "35px",
                            marginTop: "-1px",
                            backgroundColor: "#3182bd",
                          }}
                          variant="contained"
                          component="span"
                        >
                          <h4>Upload Note</h4>
                        </Button>
                      </label>
                    </Box>
                  </div>
                )}
              </Drawer>
            </React.Fragment>
          </div>

          <Grid container spacing={1.5}>
            {notesList.length == 0 ? (
              <div
                style={{
                  marginTop: "200px",
                  height: "100px",
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <div style={{ textAlign: "center" }}>
                  <Fab
                    size="large"
                    style={{
                      border: "3px solid #aaa",
                      boxShadow: "none",
                      backgroundColor: "transparent",
                    }}
                    aria-label="add"
                  >
                    <PriorityHighIcon
                      style={{
                        color: "grey",
                      }}
                    />
                  </Fab>
                  <br />
                  <h4 style={{ color: "grey" }}>No Notes uploaded.</h4>
                </div>
              </div>
            ) : (
              ""
            )}
            {notesList.map((note) => (
              <Fragment>
                <Grid item md={3}>
                  <ButtonBase
                    style={{
                      minHeight: "100%",
                      boxShadow: "0 4px 8px 0 rgba(0,0,0,0.2)",
                      borderRadius: "6px",
                      backgroundColor: "#3182bd",
                      color: "white",
                      textAlign: "left",
                      padding: "10px 5px 5px 5px",
                      justifyContent: "space-between",
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "flex-start",
                      width: "100%",
                    }}
                  >
                    <div style={{ margin: "0px 5px 5px 5px" }}>
                      <span
                        style={{
                          fontSize: "16px",
                          fontFamily: "Ubuntu Medium",
                        }}
                      >
                        {note["name"]}
                      </span>
                      <br></br>
                      <span
                        style={{
                          fontSize: "13px",
                          fontFamily: "Roboto-Regular",
                        }}
                      >
                        {note["subject"]}
                      </span>
                      <div
                        style={{
                          height: "0.5px",
                          width: "100%",
                          backgroundColor: "white",
                          margin: "10px 0px 5px 0px",
                        }}
                      ></div>
                      <div
                        style={{
                          fontSize: "13px",
                          marginTop: "10px",
                          fontFamily: "Lato-Regular",
                        }}
                      >
                        {note["description"]}
                      </div>
                    </div>
                    <div
                      style={{
                        width: "100%",
                        display: "flex",
                        justifyContent: "space-between",
                        marginTop: "5px",
                        alignItems: "center",
                      }}
                    >
                      <div
                        style={{
                          fontSize: "13px",
                          marginLeft: "5px",
                          fontFamily: "Lato-Bold",
                        }}
                      >
                        {moment(note["date"], "DD-MM-YYYY").format(
                          "Do MMMM, YYYY"
                        )}
                      </div>
                      <div style={{ display: "flex" }}>
                        <Box textAlign="right">
                          <a
                            style={{ color: "white" }}
                            target="_blank"
                            href={note["link"]}
                          >
                            <GetAppIcon style={{ marginTop: "1px" }} />
                          </a>
                        </Box>
                        <div style={{ width: "5px" }}></div>
                        <Box
                          onClick={() => {
                            setId(note["id"]);
                            setType("NOTE");
                            setDeleteItemClass(note["class"]);
                            setDeleteItemDivision(note["division"]);
                            setOpen2(true);
                          }}
                          textAlign="right"
                        >
                          <DeleteIcon />
                        </Box>
                      </div>
                    </div>
                  </ButtonBase>
                </Grid>
              </Fragment>
            ))}
          </Grid>
          <br></br>
        </Fragment>
      );
    } else if (selectedPage == "ASSIGNMENTS") {
      if (noClasses) {
        return (
          <div
            style={{
              marginTop: "200px",
              height: "100px",
              width: "100%",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <div style={{ textAlign: "center" }}>
              <Fab
                size="large"
                style={{
                  border: "3px solid #aaa",
                  boxShadow: "none",
                  backgroundColor: "transparent",
                }}
                aria-label="add"
              >
                <PriorityHighIcon
                  style={{
                    color: "grey",
                  }}
                />
              </Fab>
              <br />
              <h4 style={{ color: "grey" }}>
                No classes or subjects appointed.
              </h4>
            </div>
          </div>
        );
      }

      if (assignmentsPage == "ASSIGNMENTS") {
        return (
          <Fragment>
            <div>
              <Fragment key={"right"}>
                <Fab
                  size="large"
                  style={{
                    boxShadow: "none",
                    backgroundColor: "#ff3333 ",
                    position: "fixed",
                    bottom: 30,
                    right: 30,
                  }}
                  onClick={() => setToggleDrawer(true)}
                  aria-label="add"
                >
                  <PublishIcon
                    style={{
                      color: "white",
                    }}
                  />
                </Fab>
                <Drawer
                  anchor={"right"}
                  open={toggleDrawer}
                  onClose={() => setToggleDrawer(false)}
                >
                  {isSubmitting ? (
                    <div
                      style={{
                        marginTop: "50px",
                        padding: "20px",
                        width: "400px",
                        backgroundColor: "white",
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                        height: "100%",
                      }}
                    >
                      <CircularProgress />
                      <h3>Uploading in progress...</h3>
                    </div>
                  ) : (
                    <div
                      style={{
                        marginTop: "40px",
                        padding: "20px",
                        width: "400px",
                      }}
                    >
                      <span
                        style={{
                          color: "#3182bd",
                          fontFamily: "Lato-Regular",
                          fontSize: "14px",
                        }}
                      >
                        Semester
                      </span>
                      <Select
                        value={semester}
                        onChange={(e) => handleSemesterChange(e)}
                        style={{
                          marginTop: "2px",
                          height: "40px",
                          marginBottom: "5px",
                        }}
                        fullWidth
                        variant={"outlined"}
                      >
                        {getSemesterList()}
                      </Select>
                      <Grid container spacing={1}>
                        <Grid item xs={6}>
                          <span
                            style={{
                              color: "#3182bd",
                              fontFamily: "Lato-Regular",
                              fontSize: "14px",
                            }}
                          >
                            Class
                          </span>
                          <Select
                            value={classs}
                            onChange={(e) => handleClassChange(e)}
                            style={{
                              marginTop: "2px",
                              height: "40px",
                              marginBottom: "5px",
                            }}
                            fullWidth
                            variant={"outlined"}
                          >
                            {getClassList()}
                          </Select>
                        </Grid>
                        <Grid item xs={6}>
                          <span
                            style={{
                              color: "#3182bd",
                              fontFamily: "Lato-Regular",
                              fontSize: "14px",
                            }}
                          >
                            Division
                          </span>
                          <Select
                            value={division}
                            onChange={(e) => handleDivisionChange(e)}
                            style={{
                              marginTop: "2px",
                              height: "40px",
                              marginBottom: "5px",
                            }}
                            fullWidth
                            variant={"outlined"}
                          >
                            {getDivisionList()}
                          </Select>
                        </Grid>
                      </Grid>
                      <span
                        style={{
                          color: "#3182bd",
                          fontFamily: "Lato-Regular",
                          fontSize: "14px",
                        }}
                      >
                        Subject
                      </span>
                      <Select
                        value={subject}
                        onChange={(e) => handleSubjectChange(e)}
                        style={{
                          marginTop: "2px",
                          height: "40px",
                          marginBottom: "5px",
                        }}
                        fullWidth
                        variant={"outlined"}
                      >
                        {getSubjectList()}
                      </Select>
                      <span
                        style={{
                          color: "#3182bd",
                          fontFamily: "Lato-Regular",
                          fontSize: "14px",
                        }}
                      >
                        Completion Date
                      </span>
                      <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DesktopDatePicker
                          inputFormat="dd-MM-yyyy"
                          value={selectedDate}
                          onChange={handleDateChange}
                          renderInput={(params) => (
                            <TextField
                              style={{
                                marginTop: "2px",
                                marginBottom: "5px",
                              }}
                              size="small"
                              fullWidth
                              {...params}
                            />
                          )}
                        />
                      </LocalizationProvider>
                      <span
                        style={{
                          color: "#3182bd",
                          fontFamily: "Lato-Regular",
                          fontSize: "14px",
                        }}
                      >
                        Name
                      </span>
                      <TextField
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                        fullWidth
                        size="small"
                        style={{
                          marginTop: "2px",
                          height: "40px",
                          marginBottom: "5px",
                        }}
                        variant={"outlined"}
                        placeholder="Assignment name"
                      />
                      <span
                        style={{
                          color: "#3182bd",
                          fontFamily: "Lato-Regular",
                          fontSize: "14px",
                        }}
                      >
                        Description
                      </span>
                      <TextField
                        value={description}
                        style={{
                          marginTop: "2px",
                          height: "40px",
                          marginBottom: "5px",
                        }}
                        onChange={(e) => setDescription(e.target.value)}
                        fullWidth
                        placeholder="Description"
                        variant="outlined"
                        multiline
                        rows={4}
                      />
                      <br></br>
                      <br></br>
                      <br></br>
                      <br></br> <br></br>
                      <Box
                        style={{
                          width: "100%",
                          justifyContent: "center",
                          display: "flex",
                        }}
                      >
                        <label
                          style={{ width: "100%" }}
                          htmlFor="contained-button-file"
                        >
                          <input
                            style={{ display: "none" }}
                            id="contained-button-file"
                            type="file"
                            onChange={(e) => onFileChange(e)}
                          />

                          <Button
                            style={{
                              width: "100%",
                              fontSize: "15px",
                              height: "35px",
                              marginTop: "-1px",
                              backgroundColor: "#3182bd",
                            }}
                            variant="contained"
                            component="span"
                          >
                            <h4>Upload Assignment</h4>
                          </Button>
                        </label>
                      </Box>
                    </div>
                  )}
                </Drawer>
              </Fragment>
            </div>
            {assignmentList.length == 0 ? (
              <div
                style={{
                  marginTop: "200px",
                  height: "100px",
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <div style={{ textAlign: "center" }}>
                  <Fab
                    size="large"
                    style={{
                      border: "3px solid #aaa",
                      boxShadow: "none",
                      backgroundColor: "transparent",
                    }}
                    aria-label="add"
                  >
                    <PriorityHighIcon
                      style={{
                        color: "grey",
                      }}
                    />
                  </Fab>
                  <br />
                  <h4 style={{ color: "grey" }}>No assignments uploaded.</h4>
                </div>
              </div>
            ) : (
              ""
            )}

            <Grid container spacing={1.5}>
              {assignmentList.map((assignment, index) => (
                <Fragment>
                  <Grid item md={3}>
                    <ButtonBase
                      onClick={() => {
                        setAssignmentIndex(index);
                        setAssignmentsPage("STUDENTS");
                      }}
                      style={{
                        minHeight: "100%",
                        boxShadow: "0 4px 8px 0 rgba(0,0,0,0.2)",
                        borderRadius: "6px",
                        backgroundColor:
                          assignment["submitted"] ==
                          assignment["total_students"]
                            ? "#31a354"
                            : "#3182bd",
                        color: "white",
                        textAlign: "left",
                        padding: "10px 5px 5px 5px",
                        justifyContent: "space-between",
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "flex-start",
                        width: "100%",
                      }}
                    >
                      <div style={{ margin: "0px 5px 5px 5px" }}>
                        <span
                          style={{
                            fontSize: "16px",
                            fontFamily: "Ubuntu Medium",
                          }}
                        >
                          {assignment["name"]}
                        </span>
                        <br></br>
                        <span
                          style={{
                            fontSize: "13px",
                            fontFamily: "Roboto-Regular",
                          }}
                        >
                          {assignment["subject"]}
                        </span>
                        <div
                          style={{
                            height: "0.5px",
                            width: "100%",
                            backgroundColor: "white",
                            margin: "10px 0px 5px 0px",
                          }}
                        ></div>
                        <div
                          style={{
                            fontSize: "13px",
                            marginTop: "10px",
                            fontFamily: "Lato-Regular",
                          }}
                        >
                          {assignment["description"]}
                        </div>
                      </div>
                      <div
                        style={{
                          width: "100%",
                          display: "flex",
                          justifyContent: "space-between",
                          marginTop: "5px",
                          alignItems: "center",
                          marginBottom: "3px",
                        }}
                      >
                        <div>
                          <div
                            style={{
                              fontSize: "11px",
                              marginLeft: "5px",
                              fontFamily: "Lato-Bold",
                            }}
                          >
                            Submit By
                            <span
                              style={{
                                fontFamily: "Lato-Regular",
                                marginLeft: "8px",
                              }}
                            >
                              {" "}
                              {"      " +
                                moment(
                                  assignment["completion_date"],
                                  "DD-MM-YYYY"
                                ).format("Do MMMM, YYYY")}
                            </span>
                          </div>
                          <div
                            style={{
                              fontSize: "11px",
                              marginLeft: "5px",
                              fontFamily: "Lato-Bold",
                            }}
                          >
                            Submitted
                            <span
                              style={{
                                fontFamily: "Lato-Regular",
                                marginLeft: "6px",
                              }}
                            >
                              {" "}
                              {"      " +
                                assignment["submitted"] +
                                " / " +
                                assignment["total_students"]}
                            </span>
                          </div>
                        </div>

                        <div style={{ display: "flex" }}>
                          <Box textAlign="right">
                            <a
                              style={{ color: "white" }}
                              target="_blank"
                              href={assignment["link"]}
                            >
                              <GetAppIcon
                                style={{
                                  marginTop: "1px",
                                }}
                              />
                            </a>
                          </Box>
                          <div style={{ width: "5px" }}></div>
                          <Box
                            onClick={() => {
                              setId(assignment["id"]);
                              setType("ASSIGNMENT");
                              setDeleteItemClass(assignment["class"]);
                              setDeleteItemDivision(assignment["division"]);
                              setOpen2(true);
                            }}
                            textAlign="right"
                          >
                            <DeleteIcon />
                          </Box>
                        </div>
                      </div>
                    </ButtonBase>
                  </Grid>
                </Fragment>
              ))}
            </Grid>
            <br></br>
          </Fragment>
        );
      } else {
        return (
          <div style={{ marginTop: "15px" }}>
            <div style={{ display: "flex", marginBottom: "15px" }}>
              <Button
                fullWidth
                style={{
                  backgroundColor: "#3182bd",
                  color: "white",
                  width: "5%",
                  height: "35px",
                  borderTopLeftRadius: "6px",
                  borderBottomLeftRadius: "6px",
                  borderTopRightRadius: "0px",
                  borderBottomRightRadius: "0px",
                }}
                onClick={() => setAssignmentsPage("ASSIGNMENTS")}
              >
                <ArrowBackIcon style={{ fontSize: "25px" }} />
              </Button>
              <div
                textAlign="center"
                style={{
                  color: "#084594",
                  backgroundColor: "#d7ecff",
                  width: "95%",
                  height: "35px",
                  padding: "3px 0px 0px 25px",
                  alignItems: "center",
                  borderTopLeftRadius: "0px",
                  borderBottomLeftRadius: "0px",
                  borderTopRightRadius: "6px",
                  borderBottomRightRadius: "6px",
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <div
                  style={{
                    marginLeft: "-10px",
                    display: "flex",
                    justifyContent: "space-between",
                    width: "100%",
                  }}
                >
                  <h2 style={{ fontSize: "20px" }}>
                    {assignmentList[assignmentIndex]["name"]}
                  </h2>
                  <div
                    style={{
                      fontSize: "20px",
                      fontWeight: "bold",
                      fontFamily: "Bahnschrift",
                      fontWeight: "lighter",
                      marginTop: "17px",
                    }}
                  >
                    {moment(
                      assignmentList[assignmentIndex]["completion_date"],
                      "DD-MM-YYYY"
                    ).format("Do MMMM, YYYY")}
                  </div>
                  <div
                    style={{
                      fontSize: "20px",
                      fontWeight: "bold",
                      fontFamily: "Bahnschrift",
                      fontWeight: "lighter",
                      marginTop: "17px",
                    }}
                  >
                    {assignmentList[assignmentIndex]["class_id"] +
                      " " +
                      assignmentList[assignmentIndex]["division"]}
                  </div>
                </div>
              </div>
            </div>
            <Grid container spacing={1.5} style={{ margin: "0px" }}>
              {assignmentList[assignmentIndex]["student_list"].map(
                (student) => (
                  <Grid
                    item
                    md={3}
                    style={{
                      padding: "10px",
                      border: "2px solid #d7ecff",
                      borderRadius: "6px",
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <div>
                      <h3 style={{ marginTop: "0px", marginBottom: "0px" }}>
                        {student["roll_number"] + ". " + student["name"]}
                      </h3>

                      <span
                        style={{
                          fontSize: "13px",
                          color: student["submitted"]
                            ? moment(
                                student["submission_date"],
                                "DD-MM-YYYY"
                              ).isAfter(
                                moment(
                                  assignmentList[assignmentIndex][
                                    "completion_date"
                                  ],
                                  "DD-MM-YYYY"
                                ),
                                "day"
                              )
                              ? "#ff3333"
                              : "#31a354"
                            : "#3182bd",
                        }}
                      >
                        {student["submitted"]
                          ? "Submitted on " +
                            moment(
                              student["submission_date"],
                              "DD-MM-YYYY"
                            ).format("Do MMMM, YYYY")
                          : "Submission Pending"}
                      </span>
                    </div>
                    <Box
                      textAlign="center"
                      p={1}
                      style={{
                        height: "35px",
                        width: "35px",
                        borderRadius: "60px",
                        backgroundColor: student["submitted"]
                          ? moment(
                              student["submission_date"],
                              "DD-MM-YYYY"
                            ).isAfter(
                              moment(
                                assignmentList[assignmentIndex][
                                  "completion_date"
                                ],
                                "DD-MM-YYYY"
                              ),
                              "day"
                            )
                            ? "#ff3333"
                            : "#31a354"
                          : "#3182bd",
                        color: "white",
                      }}
                    >
                      {student["submitted"] ? (
                        <CheckIcon
                          style={{
                            fontSize: "30px",
                            margin: "-5px 0px 0px -5.5px",
                          }}
                        />
                      ) : (
                        <PriorityHighIcon
                          style={{
                            fontSize: "30px",
                            margin: "-5px 0px 0px -5.5px",
                          }}
                        />
                      )}
                    </Box>
                  </Grid>
                )
              )}
            </Grid>
          </div>
        );
      }
    } else if (selectedPage == "LEAVEREQUESTS") {
      return (
        <div style={{ marginTop: "98px" }}>
          <Grid container spacing={3} style={{ marginTop: "-50px" }}>
            <Grid item md={8}>
              {leaveRequestList.length == 0 ? (
                <div
                  style={{
                    marginTop: "200px",
                    height: "100px",
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <div style={{ textAlign: "center" }}>
                    <Fab
                      size="large"
                      style={{
                        border: "3px solid #aaa",
                        boxShadow: "none",
                        backgroundColor: "transparent",
                      }}
                      aria-label="add"
                    >
                      <PriorityHighIcon
                        style={{
                          color: "grey",
                        }}
                      />
                    </Fab>
                    <br />
                    <h4 style={{ color: "grey" }}>No Leave requests.</h4>
                  </div>
                </div>
              ) : (
                ""
              )}
              {leaveRequestList.map((leave, index) => (
                <Box
                  p={2}
                  style={{
                    height: "80px",
                    marginBottom: "15px",
                    borderRadius: "15px",
                    border:
                      leave["status"] == "PENDING"
                        ? "1.5px solid" + "#3182bd"
                        : "none",
                    backgroundColor:
                      leave["status"] == "PENDING"
                        ? "white"
                        : leave["status"] == "APPROVED"
                        ? colorList[0]
                        : colorList[2],
                    color: "black",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItemsL: "center",
                    }}
                  >
                    <div style={{ width: "90%" }}>
                      <div
                        style={{
                          margin: "-25px 0px 0px -3px",
                        }}
                      >
                        <h3
                          style={{
                            fontFamily: "Lato-Bold",
                            lineHeight: "20px",
                            color:
                              leave["status"] == "PENDING"
                                ? colorList2[1]
                                : leave["status"] == "APPROVED"
                                ? colorList2[0]
                                : colorList2[2],
                          }}
                        >
                          {leave["subject"]}
                        </h3>
                      </div>
                      <div
                        style={{
                          margin: "-15px 0px 0px -3px",
                          fontSize: "14px",
                        }}
                      >
                        {moment(leave["date"], "DD-MM-YYYY").format(
                          "Do MMMM, YYYY"
                        )}
                      </div>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "flex-end",
                        }}
                      >
                        {leave["status"] == "PENDING" ? (
                          <ButtonBase
                            style={{
                              cursor: "pointer",
                              marginLeft: "-10px",
                              display: "flex",
                              backgroundColor: "#ff3333",
                              borderRadius: "6px",
                              padding: "3px",
                              alignItems: "center",
                              justifyContent: "space-between",
                              position: "relative",
                              bottom: "62px",
                              height: "25px",
                              width: "25px",
                              right: "-105px",
                            }}
                            onClick={() => {
                              setId(leave["id"]);
                              setType("LEAVEREQUEST");
                              setOpen2(true);
                            }}
                          >
                            <DeleteIcon
                              style={{ color: "white", fontSize: "18px" }}
                            />
                          </ButtonBase>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>

                    <div
                      style={{
                        width: "100px",
                        display: "flex",
                        justifyContent: "center",
                        marginTop:
                          leave["status"] == "PENDING" ? "-12px" : "-5px",
                        marginRight: "-15px",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          flexDirection: "column",
                          alignItems: "center",
                        }}
                      >
                        <Box
                          textAlign="center"
                          p={1}
                          style={{
                            height: "35px",
                            width: "35px",
                            borderRadius: "60px",
                            backgroundColor:
                              leave["status"] == "PENDING"
                                ? colorList2[1]
                                : leave["status"] == "APPROVED"
                                ? colorList2[0]
                                : colorList2[2],
                            color: "white",
                          }}
                        >
                          {leave["status"] == "PENDING" ? (
                            <PriorityHighIcon
                              style={{
                                fontSize: "30px",
                                margin: "-5px 0px 0px -5.5px",
                              }}
                            />
                          ) : leave["status"] == "APPROVED" ? (
                            <CheckIcon
                              style={{
                                fontSize: "30px",
                                margin: "-5px 0px 0px -5.5px",
                              }}
                            />
                          ) : (
                            <CloseIcon
                              style={{
                                fontSize: "30px",
                                margin: "-5px 0px 0px -5.5px",
                              }}
                            />
                          )}
                        </Box>
                        <b
                          style={{
                            marginTop: "5px",
                            color:
                              leave["status"] == "PENDING"
                                ? colorList2[1]
                                : leave["status"] == "APPROVED"
                                ? colorList2[0]
                                : colorList2[2],
                          }}
                        >
                          <span
                            style={{
                              fontFamily: "Bahnschrift",
                              fontWeight: "lighter",
                            }}
                          >
                            {leave["status"]}
                          </span>
                        </b>
                      </div>
                    </div>
                  </div>
                </Box>
              ))}
            </Grid>
            <Grid item md={4}>
              <div style={{ marginTop: "-25px" }}>
                <h3>Apply for Leave</h3>
              </div>
              <div
                style={{
                  color: "#3182bd",
                  fontFamily: "Lato-Regular",
                  fontSize: "14px",
                  marginTop: "-10px",
                }}
              >
                Choose a date
              </div>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DesktopDatePicker
                  inputFormat="dd-MM-yyyy"
                  value={selectedDate}
                  onChange={handleDateChange}
                  renderInput={(params) => (
                    <TextField
                      style={{
                        marginTop: "2px",
                        marginBottom: "5px",
                      }}
                      size="small"
                      fullWidth
                      {...params}
                    />
                  )}
                />
              </LocalizationProvider>
              <br></br>
              <span
                style={{
                  color: "#3182bd",
                  fontFamily: "Lato-Regular",
                  fontSize: "14px",
                }}
              >
                Class
              </span>
              <TextField
                value={leaveSubject}
                style={{ marginTop: "2px" }}
                onChange={(e) => setLeaveSubject(e.target.value)}
                fullWidth
                placeholder="Reason for leave"
                variant="outlined"
                size="small"
              />
              <br></br>
              <br></br>
              <div style={{ display: "flex", justifyContent: "center" }}>
                <label
                  style={{ width: "100%" }}
                  htmlFor="contained-button-file"
                >
                  <input
                    style={{ display: "none" }}
                    id="contained-button-file"
                    type="file"
                    onChange={(e) => onFileChange(e)}
                  />

                  <Button
                    style={{
                      width: "100%",
                      fontSize: "15px",
                      height: "35px",
                      marginTop: "-1px",
                      backgroundColor: "#3182bd",
                    }}
                    variant="contained"
                    component="span"
                  >
                    <h4>Upload Request Application</h4>
                  </Button>
                </label>
              </div>
              <br></br>
              {isSubmitting ? (
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <CircularProgress />
                </div>
              ) : (
                ""
              )}
            </Grid>
          </Grid>
        </div>
      );
    }
  }

  if (!state.auth.isLoading) {
    if (state.auth.isAuthenticated) {
      if (state.auth.user.is_teacher) {
        if (isLoading) {
          return (
            <Fragment>
              <Sidebar />
              <div
                style={{
                  maxWidth: "88vw",
                  marginTop: "45vh",
                  marginLeft: "8vw",
                  backgroundColor: "white",
                  height: "100%",
                  width: "100%",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <CircularProgress />
              </div>
            </Fragment>
          );
        } else {
          return (
            <Fragment>
              <Sidebar
                tabs={
                  <Grid container spacing={1.5}>
                    <Grid item md={4}>
                      {selectedPage == "NOTES" ? (
                        <Button
                          fullWidth
                          value={"NOTES"}
                          onClick={handlePageSelect}
                          p={2}
                          style={{
                            backgroundColor: "#3182bd",
                            color: "white",
                            height: "30px",
                          }}
                          borderRadius={6}
                        >
                          <h3
                            style={{
                              marginTop: "16px",
                              fontSize: "15px",
                            }}
                          >
                            NOTES
                          </h3>
                        </Button>
                      ) : (
                        <Button
                          fullWidth
                          value={"NOTES"}
                          onClick={handlePageSelect}
                          p={2}
                          style={{
                            backgroundColor: "#d7ecff",
                            color: "white",
                            height: "30px",
                          }}
                          borderRadius={6}
                        >
                          <h3
                            style={{
                              marginTop: "16px",
                              fontSize: "15px",
                              color: "#084594",
                            }}
                          >
                            NOTES
                          </h3>
                        </Button>
                      )}
                    </Grid>
                    <Grid item md={4}>
                      {selectedPage == "ASSIGNMENTS" ? (
                        <Button
                          fullWidth
                          value="ASSIGNMENTS"
                          onClick={handlePageSelect}
                          p={2}
                          style={{
                            backgroundColor: "#3182bd",
                            color: "white",
                            height: "30px",
                          }}
                          borderRadius={6}
                        >
                          <h3
                            style={{
                              marginTop: "16px",
                              fontSize: "15px",
                            }}
                          >
                            ASSIGNMENTS
                          </h3>
                        </Button>
                      ) : (
                        <Button
                          fullWidth
                          value="ASSIGNMENTS"
                          onClick={handlePageSelect}
                          p={2}
                          style={{
                            backgroundColor: "#d7ecff",
                            color: "white",
                            height: "30px",
                          }}
                          borderRadius={6}
                        >
                          <h3
                            style={{
                              marginTop: "16px",
                              fontSize: "15px",
                              color: "#084594",
                            }}
                          >
                            ASSIGNMENTS
                          </h3>
                        </Button>
                      )}
                    </Grid>
                    <Grid item md={4}>
                      {selectedPage == "LEAVEREQUESTS" ? (
                        <Button
                          fullWidth
                          value="LEAVEREQUESTS"
                          onClick={handlePageSelect}
                          p={2}
                          style={{
                            backgroundColor: "#3182bd",
                            color: "white",
                            height: "30px",
                          }}
                          borderRadius={6}
                        >
                          <h3
                            style={{
                              marginTop: "16px",
                              fontSize: "15px",
                            }}
                          >
                            LEAVE REQUESTS
                          </h3>
                        </Button>
                      ) : (
                        <Button
                          fullWidth
                          value="LEAVEREQUESTS"
                          onClick={handlePageSelect}
                          p={2}
                          style={{
                            backgroundColor: "#d7ecff",
                            color: "white",
                            height: "30px",
                          }}
                          borderRadius={6}
                        >
                          <h3
                            style={{
                              marginTop: "16px",
                              fontSize: "15px",
                              color: "#084594",
                            }}
                          >
                            LEAVE REQUESTS
                          </h3>
                        </Button>
                      )}
                    </Grid>
                  </Grid>
                }
              />
              <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                open={open}
                onClose={() => setOpen(false)}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                  timeout: 500,
                }}
              >
                <Fade in={open}>
                  <div
                    style={{
                      position: "absolute",
                      top: "50%",
                      backgroundColor: "white",
                      left: "50%",
                      transform: "translate(-50%, -50%)",
                      width: 400,
                      padding: "20px",
                    }}
                  >
                    {fileData()}
                  </div>
                </Fade>
              </Modal>
              <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                open={open2}
                onClose={() => setOpen2(false)}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                  timeout: 500,
                }}
              >
                <Fade in={open2}>
                  <div
                    style={{
                      position: "absolute",
                      top: "50%",
                      backgroundColor: "white",
                      left: "50%",
                      transform: "translate(-50%, -50%)",
                      width: 400,
                      padding: "20px",
                    }}
                  >
                    <div style={{ marginTop: "-25px" }}>
                      <div
                        style={{
                          marginTop: "20px",
                          display: "flex",
                          justifyContent: "flex-end",
                        }}
                      >
                        <IconButton size="small">
                          <CloseIcon
                            onClick={() => setOpen2(false)}
                            style={{ color: "#3182bd" }}
                          />
                        </IconButton>
                      </div>
                      <h3>Delete this item?</h3>
                      <span>
                        This action cannot be reversed. It will be deleted
                        forever.
                      </span>
                      <br></br>
                      <br></br>
                      <div
                        style={{
                          display: "flex",
                          width: "100%",
                          justifyContent: "flex-end",
                        }}
                      >
                        <Button
                          onClick={() => deleteItem()}
                          style={{ height: "30px" }}
                          variant="contained"
                        >
                          Yes
                        </Button>
                        <Button
                          style={{ height: "30px", marginLeft: "10px" }}
                          variant="contained"
                          onClick={() => setOpen2(false)}
                        >
                          No
                        </Button>
                      </div>
                    </div>
                  </div>
                </Fade>
              </Modal>
              <div
                style={{
                  margin: "65px 15px 20px 85px",
                  backgroundColor: "white",
                }}
              >
                {selectedPageContent()}
              </div>
            </Fragment>
          );
        }
      } else {
        createAlert("ERROR", "Permission Denied.", "Please Login as Teacher");
        return (
          <Fragment>
            <Navigate to="/login" />
          </Fragment>
        );
      }
    } else {
      return (
        <Fragment>
          <Navigate to="/login" />
        </Fragment>
      );
    }
  } else {
    return <p>loading...</p>;
  }
}
export default Classes;
