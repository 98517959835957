import React, { useEffect, useState, useRef, Fragment } from "react";
import { useSelector, useDispatch } from "react-redux";
import makeStyles from "@mui/styles/makeStyles";
import Card from "@mui/material/Card";
import CardActionArea from "@mui/material/CardActionArea";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Sidebar from "../components/Sidebar";
import PropTypes from "prop-types";
import LinearProgress from "@mui/material/LinearProgress";
import Box from "@mui/material/Box";
import { API_URL, alertMessage } from "../actions/auth";
import Paper from "@mui/material/Paper";
import Fab from "@mui/material/Fab";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import TextField from "@mui/material/TextField";
import PublishIcon from "@mui/icons-material/Publish";
import Avatar from "@mui/material/Avatar";
import axios from "axios";
import CircularProgress from "@mui/material/CircularProgress";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import { Link, Navigate, useNavigate, Redirect } from "react-router-dom";
import LocalDiningIcon from "@mui/icons-material/LocalDining";
import GavelIcon from "@mui/icons-material/Gavel";
import BallotIcon from "@mui/icons-material/Ballot";
import ComputerIcon from "@mui/icons-material/Computer";
import HistoryIcon from "@mui/icons-material/History";
import MenuBookIcon from "@mui/icons-material/MenuBook";
import PublicIcon from "@mui/icons-material/Public";
import CalculateIcon from "@mui/icons-material/Calculate";
import Calendar from "react-calendar";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";
import SaveIcon from "@mui/icons-material/Save";
import Modal from "@mui/material/Modal";
import Backdrop from "@mui/material/Backdrop";
import Fade from "@mui/material/Fade";
import Checkbox from "@mui/material/Checkbox";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { loadUser } from "../actions/auth";

const weekday = [
  "Sunday",
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
];

const colorList = [
  "#e5f5f9",
  "#e0ecf4",
  "#fee8c8",
  "#ece7f2",
  "#f0f0f0",
  "#fde0dd",
  "#efedf5",
  "#e5f5e0",
  "#d7ecff",
  "#fee6ce",
];
const colorList2 = [
  "#2ca25f",
  "#8856a7",
  "#e34a33",
  "#1c9099",
  "#636363",
  "#c51b8a",
  "#756bb1",
  "#31a354",
  "#3182bd",
  "#e6550d",
];

const colorList3 = [
  "#99d8c9",
  "#9ebcda",
  "#fdbb84",
  "#a6bddb",
  "#bdbdbd",
  "#fa9fb5",
  "#bcbddc",
  "#addd8e",
  "#9ecae1",
  "#a1d99b",
];

function getIcon(subject) {
  var sub = subject.toLowerCase();
  if (sub.includes("break") || sub.includes("lunch")) {
    return <LocalDiningIcon style={{ fontSize: 45, color: "black" }} />;
  } else if (sub.includes("mechanic")) {
    return (
      <MenuBookIcon
        style={{ fontSize: 43, color: "black", marginLeft: "2px" }}
      />
    );
  } else if (sub.includes("social")) {
    return (
      <MenuBookIcon
        style={{ fontSize: 43, color: "black", marginLeft: "2px" }}
      />
    );
  } else if (sub.includes("civics")) {
    return <GavelIcon style={{ fontSize: 45, color: "black" }} />;
  } else if (sub.includes("polit")) {
    return <BallotIcon style={{ fontSize: 45, color: "black" }} />;
  } else if (sub.includes("compu")) {
    return <ComputerIcon style={{ fontSize: 45, color: "black" }} />;
  } else if (sub.includes("bio")) {
    return (
      <MenuBookIcon
        style={{ fontSize: 43, color: "black", marginLeft: "2px" }}
      />
    );
  } else if (sub.includes("chem") || sub.includes("scien")) {
    return (
      <MenuBookIcon
        style={{ fontSize: 43, color: "black", marginLeft: "2px" }}
      />
    );
  } else if (
    sub.includes("economics") ||
    sub.includes("accou") ||
    sub.includes("busi")
  ) {
    return (
      <MenuBookIcon
        style={{ fontSize: 43, color: "black", marginLeft: "2px" }}
      />
    );
  } else if (
    sub.includes("geom") ||
    sub.includes("archi") ||
    (sub.includes("engg") && sub.includes("draw"))
  ) {
    return (
      <MenuBookIcon
        style={{ fontSize: 43, color: "black", marginLeft: "2px" }}
      />
    );
  } else if (sub.includes("geo")) {
    return <PublicIcon style={{ fontSize: 45, color: "black" }} />;
  } else if (sub.includes("math")) {
    return <CalculateIcon style={{ fontSize: 45, color: "black" }} />;
  } else if (sub.includes("history")) {
    return <HistoryIcon style={{ fontSize: 45, color: "black" }} />;
  } else {
    return (
      <MenuBookIcon
        style={{ fontSize: 43, color: "black", marginLeft: "2px" }}
      />
    );
  }
}

function Settings() {
  const dispatch = useDispatch();
  function createAlert(type, title, message) {
    dispatch(alertMessage(type, title, message));
  }
  const navigate = useNavigate();

  const params = new URLSearchParams(window.location.search);
  const state = useSelector((state) => state);
  const [selectedPage, setSelectedPage] = useState("PROFILE");
  const [itemIndex, setItemIndex] = useState(9999);
  const [phoneNumber, setPhoneNumber] = useState("");
  const [timeTable, setTimeTable] = useState([]);
  const [random, setRandom] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [selectedDay, setSelectedDay] = useState(weekday[new Date().getDay()]);

  const handlePageSelect = (event) => {
    setSelectedPage(event.currentTarget.value);
  };
  var colorIndex = 0;
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => {
    setOpen(true);
  };

  useEffect(() => {
    if (state.auth.isLoading) {
      dispatch({ type: "ENTRY_PAGE", payload: window.location.pathname });
      navigate("/", { replace: true });
    } else {
      setClassList(JSON.parse(state.auth.user.class_id_list));
      setTimeTable(JSON.parse(state.auth.user.time_table));
    }
  }, []);

  if (state.auth.refresh == "REFRESH_DATA") {
    dispatch(loadUser());
    dispatch({ type: "REFRESH_DATA", payload: null });
  }

  const [classList, setClassList] = useState([]);
  const handleClose = () => {
    setOpen(false);
  };

  function getSize(fileSize) {
    if (fileSize < 1024) {
      return <p>Size: {(fileSize / 1024).toFixed(2)} B</p>;
    } else if (fileSize > 1024 && fileSize < 1024 * 1024) {
      return <p>Size: {(fileSize / (1024 * 1024)).toFixed(2)} KB</p>;
    } else if (fileSize > 1024 * 1024 && fileSize < 25 * 1024 * 1024) {
      return <p>Size: {(fileSize / (1024 * 1024)).toFixed(2)} MB</p>;
    } else if (fileSize > 25 * 1024 * 1024) {
      handleClose();
      createAlert(
        "ERROR",
        "File too big.",
        "File size should be less than 25 MB."
      );
    }
  }

  function setTimeTableItemSubject(index, subject) {
    var tempTimeTable = timeTable;
    tempTimeTable[selectedDay][index][
      Object.keys(tempTimeTable[selectedDay][index])[0]
    ] = subject;
    setTimeTable(tempTimeTable);
    setRandom(makeid(5));
  }

  function setTimeTableItemClass(index, tt_class) {
    var tempTimeTable = timeTable;
    tempTimeTable[selectedDay][index]["class"] = tt_class;
    setTimeTable(tempTimeTable);
    setRandom(makeid(5));
  }

  function setTimeTableItemTime(index, time, tt_class) {
    var tempTimeTable = timeTable;
    var tempSubject =
      tempTimeTable[selectedDay][index][
        Object.keys(tempTimeTable[selectedDay][index])[0]
      ];
    delete tempTimeTable[selectedDay][index][
      Object.keys(tempTimeTable[selectedDay][index])[0]
    ];
    delete tempTimeTable[selectedDay][index]["class"];
    tempTimeTable[selectedDay][index][time] = tempSubject;
    tempTimeTable[selectedDay][index]["class"] = tt_class;

    setTimeTable(tempTimeTable);
    setRandom(makeid(5));
  }

  function addTimeTableItem() {
    var tempTimeTable = timeTable;
    tempTimeTable[selectedDay].push({ Time: "Subject" });
    setTimeTable(tempTimeTable);
    setRandom(makeid(5));
  }

  function deleteTimeTableItem(index) {
    var tempTimeTable = timeTable;
    tempTimeTable[selectedDay].splice(index, 1);
    setTimeTable(tempTimeTable);
    setRandom(makeid(5));
  }

  function makeid(length) {
    var result = "";
    var characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    var charactersLength = characters.length;
    for (var i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  }

  function updateTimeTable() {
    setIsSubmitting(true);
    var body = {};
    const config = {
      headers: {
        Authorization: state.auth.token,
        "X-CSRFToken": state.auth.csrfToken,
        "Content-Type": "application/json",
      },
    };

    var body = {};
    body["email"] = state.auth.user.email;
    body["timetable"] = timeTable;
    body = JSON.stringify(body);
    axios
      .post(API_URL + "/update_time_table_teacher", body, config)
      .then((res) => {
        setIsSubmitting(false);
        createAlert("SUCCESS", "Success", "Timetable updated.");
      });
  }

  function changePhoneNumber() {
    if (
      phoneNumber.length == 0 ||
      (phoneNumber.length > 15 && /\D/.test(phoneNumber))
    ) {
      createAlert("ERROR", "Error", "Enter valid phone number.");
    } else {
      var body = {};
      const config = {
        headers: {
          Authorization: state.auth.token,
          "X-CSRFToken": state.auth.csrfToken,
          "Content-Type": "application/json",
        },
      };

      body["email"] = state.auth.user.email;
      body["phone_number"] = phoneNumber;
      body = JSON.stringify(body);
      axios.post(API_URL + "/change_phone_number", body, config).then((res) => {
        createAlert("SUCCESS", "Success", "Phone Number updated.");
      });
    }
  }

  function fileData() {
    if (selectedFile) {
      return (
        <div>
          <h2>File Details:</h2>
          <p>File Name: {selectedFile.name}</p>
          <p>File Type: {selectedFile.type}</p>

          {getSize(selectedFile.size)}
          <br></br>
          <br></br>
          {selectedFile.size > 25 * 1024 * 1024 ? (
            <Button variant="contained" color="white">
              <PublishIcon />
              &nbsp;&nbsp; UPLOAD DOCUMENTS
            </Button>
          ) : (
            <Button
              variant="contained"
              onClick={() => onFileUpload()}
              color="primary"
            >
              <PublishIcon />
              &nbsp;&nbsp; UPLOAD DOCUMENTS
            </Button>
          )}
        </div>
      );
    } else {
      return (
        <div>
          <h4>Choose before Pressing the Upload button</h4>
        </div>
      );
    }
  }

  function onFileUpload() {
    const formData = new FormData();
    formData.append("file", selectedFile, selectedFile.name);
    var profilPicName =
      state.auth.user.email.replace("@", "").replace(".", "") +
      makeid(5) +
      ".jpg";
    formData.append("email", state.auth.user.email);
    formData.append("user_image", profilPicName); // Details of the uploaded file
    console.log(selectedFile);

    // Request made to the backend api
    // Send formData object

    const config = {
      headers: {
        Authorization: state.auth.token,
        "X-CSRFToken": state.auth.csrfToken,
        // "Content-Type": "application/json",
      },
    };
    var time = new Date();

    axios
      .post(API_URL + "/upload_profile_picture", formData, config)
      .then(() => {
        window.location.reload();
      });

    document.getElementById("contained-button-file").value = "";
  }

  function onFileChange(event) {
    setSelectedFile(event.target.files[0]);
  }

  function onFileClick(event) {
    // Update the state
    document.getElementById("contained-button-file").value = "";
  }
  const didMount = useRef(false);

  const [selectedFile, setSelectedFile] = useState("");
  useEffect(() => {
    if (!didMount.current) {
      return (didMount.current = true);
    }
    onFileUpload();
  }, [selectedFile]);

  function selectedPageContent() {
    if (selectedPage == "PROFILE") {
      return (
        <Grid container spacing={10}>
          <Grid item md={6}>
            <h2>Personal Details</h2>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <span>
                <span>
                  Name :
                  <b>
                    {" " +
                      state.auth.user.firstname +
                      " " +
                      state.auth.user.lastname}
                  </b>
                </span>
              </span>

              <input
                id="contained-button-file"
                style={{
                  display: "none",
                  height: "150px",
                  cursor: "pointer",
                  width: "150px",
                }}
                type="file"
                onChange={(e) => onFileChange(e)}
                onClick={(e) => onFileClick(e)}
              />
              <label
                style={{
                  width: "100%",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                }}
                htmlFor="contained-button-file"
              >
                <Avatar
                  style={{
                    height: "150px",
                    width: "150px",
                    marginTop: "-50px",
                    cursor: "pointer",
                  }}
                  alt={state.auth.user.firstname}
                  src={API_URL + "/media/" + state.auth.user.user_image}
                />
                <h5 style={{ textAlign: "center", marginTop: "5px" }}>
                  UPLOAD PHOTO
                </h5>
              </label>
            </div>
            <br></br>
            <br></br>
            <span>
              Email : <b>{state.auth.user.email}</b>
            </span>{" "}
            <br></br>
            <span>
              Phone Number : <b>{state.auth.user.phone_number}</b>
            </span>
            <br></br>
            <div style={{ display: "flex", justifyContent: "flex-end" }}>
              <TextField
                style={{ marginTop: "10px" }}
                fullWidth
                size="small"
                placeholder="Phone Number"
                variant="outlined"
                value={phoneNumber}
                onChange={(e) => setPhoneNumber(e.target.value)}
              />
              <Button
                style={{
                  fontSize: "15px",
                  height: "40px",
                  marginTop: "10px",
                  marginLeft: "10px",
                }}
                onClick={() => {
                  changePhoneNumber();
                }}
                variant="contained"
                color="primary"
              >
                <h5>Change</h5>
              </Button>
            </div>
          </Grid>
          <Grid item md={6}>
            <div>
              <h2>Classes Appointed</h2>
              {console.log(classList)}
              {Object.keys(classList).map((semester) => (
                <Fragment>
                  <h3>{semester}</h3>
                  {Object.keys(classList[semester]).map((classs) => (
                    <Fragment>
                      {Object.keys(classList[semester][classs]).map(
                        (division) => (
                          <Fragment>
                            <b> {classs + " " + division}</b>
                            <br></br>
                            {classList[semester][classs][division].map(
                              (subject) => (
                                <span>
                                  {Object.keys(subject)[0]}
                                  <br></br>
                                </span>
                              )
                            )}
                            <br></br>
                          </Fragment>
                        )
                      )}
                    </Fragment>
                  ))}
                </Fragment>
              ))}
            </div>
            <br></br>
            <br></br>
          </Grid>
        </Grid>
      );
    } else if (selectedPage == "TIMETABLE") {
      return (
        <div style={{ marginTop: "-35px" }}>
          <Grid container spacing={3}>
            <Grid item xs={8}>
              <h2>
                Timetable for
                <span style={{ color: "#3182bd" }}> {selectedDay}</span>
              </h2>

              {timeTable[selectedDay] == "" ? (
                <Box
                  height={60}
                  borderRadius="6px"
                  style={{
                    backgroundColor: colorList[colorIndex],
                    borderBottom: "2px solid " + colorList2[colorIndex],
                    width: "100%",
                    padding: "2px",
                  }}
                >
                  <div
                    style={{
                      margin: "2px 0px 0px 12px",
                      color: colorList2[colorIndex],
                      fontSize: "20px",
                    }}
                  >
                    No lectures today!
                  </div>
                  <div
                    style={{
                      margin: "-3px 0px 0px 12px",
                      color: colorList2[colorIndex],
                      fontSize: "15px",
                    }}
                  >
                    Press The " + " button to add lectures.
                  </div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      marginTop: "30px",
                    }}
                  >
                    <Fab
                      size="small"
                      style={{
                        boxShadow: "none",
                        backgroundColor: "#3182bd",
                      }}
                      onClick={() => addTimeTableItem(selectedDay)}
                      aria-label="add"
                    >
                      <AddIcon
                        style={{
                          color: "white",
                        }}
                      />
                    </Fab>
                  </div>
                </Box>
              ) : (
                <Fragment>
                  {timeTable[selectedDay].map((lecture, index) => (
                    <Fragment>
                      <Grid
                        container
                        spacing={0}
                        justifyContent="flex-start"
                        alignItems="center"
                      >
                        <Grid md={1}>
                          {itemIndex == index ? (
                            <TextField
                              style={{
                                marginTop: "2px",
                                marginLeft: "-5px",
                                width: "75px",
                              }}
                              InputProps={{
                                textAlign: "center",
                                disableUnderline: true,
                                style: {
                                  color: "#3182bd",
                                  textAlign: "center",
                                },
                              }}
                              value={Object.keys(lecture)[0]}
                              fullWidth
                              size="small"
                              onChange={(e) =>
                                setTimeTableItemTime(
                                  index,
                                  e.target.value,
                                  lecture["class"]
                                )
                              }
                              placeholder={"Time"}
                              variant="outlined"
                            />
                          ) : (
                            <p
                              style={{
                                fontSize: "15px",
                                marginTop: "12px",
                                marginLeft: "15px",
                              }}
                            >
                              {Object.keys(lecture)[0]}
                              <span style={{ display: "none" }}>
                                {
                                  (colorIndex = Math.floor(
                                    Math.random() * colorList.length
                                  ))
                                }
                              </span>
                            </p>
                          )}
                        </Grid>
                        <Grid item md={8}>
                          <Box
                            borderRadius="10px"
                            style={
                              Object.values(lecture)[0]
                                .toLowerCase()
                                .includes("break") ||
                              Object.values(lecture)[0]
                                .toLowerCase()
                                .includes("lunch")
                                ? {
                                    border: "2px solid grey",
                                    padding: "2px",
                                    display: "flex",
                                    height:
                                      itemIndex == index ? "100%" : "55px",
                                  }
                                : {
                                    padding: "2px",
                                    display: "flex",
                                    padding: "2px",
                                    height:
                                      itemIndex == index ? "100%" : "55px",
                                    backgroundColor: colorList[colorIndex],
                                  }
                            }
                          >
                            <div
                              style={{
                                margin:
                                  Object.values(lecture)[0]
                                    .toLowerCase()
                                    .includes("break") ||
                                  Object.values(lecture)[0]
                                    .toLowerCase()
                                    .includes("lunch")
                                    ? "0px 0px 0px 10px"
                                    : "2px 0px 0px 12px",
                                color: colorList2[colorIndex],
                              }}
                            >
                              {getIcon(Object.values(lecture)[0])}
                            </div>
                            <div>
                              <div>
                                {itemIndex == index ? (
                                  <TextField
                                    style={{
                                      marginTop: "5px",
                                      marginLeft: "10px",
                                      backgroundColor: "white",
                                    }}
                                    InputProps={{
                                      textAlign: "center",
                                      disableUnderline: true,
                                      style: {
                                        color: "#3182bd",
                                        textAlign: "center",
                                      },
                                    }}
                                    value={Object.values(lecture)[0]}
                                    fullWidth
                                    size="small"
                                    onChange={(e) =>
                                      setTimeTableItemSubject(
                                        index,
                                        e.target.value
                                      )
                                    }
                                    placeholder={"Subject"}
                                    variant="outlined"
                                  />
                                ) : (
                                  <Fragment>
                                    <div
                                      style={{
                                        margin:
                                          Object.values(lecture)[0]
                                            .toLowerCase()
                                            .includes("break") ||
                                          Object.values(lecture)[0]
                                            .toLowerCase()
                                            .includes("lunch")
                                            ? "-3px 0px 0px 12px"
                                            : "0px 0px 0px 12px",
                                        color:
                                          Object.values(lecture)[0]
                                            .toLowerCase()
                                            .includes("break") ||
                                          Object.values(lecture)[0]
                                            .toLowerCase()
                                            .includes("lunch")
                                            ? "black"
                                            : colorList2[colorIndex],
                                        fontSize: "20px",
                                      }}
                                    >
                                      {Object.values(lecture)[0]}
                                    </div>
                                  </Fragment>
                                )}
                              </div>
                              <br></br>
                              {itemIndex == index ? (
                                <TextField
                                  style={{
                                    marginLeft: "10px",
                                    marginTop: "-17px",
                                    marginBottom: "5px",
                                    backgroundColor: "white",
                                  }}
                                  InputProps={{
                                    textAlign: "center",
                                    disableUnderline: true,
                                    style: {
                                      color: "#3182bd",
                                      textAlign: "center",
                                    },
                                  }}
                                  value={lecture["class"]}
                                  fullWidth
                                  size="small"
                                  onChange={(e) =>
                                    setTimeTableItemClass(index, e.target.value)
                                  }
                                  placeholder={"Classroom"}
                                  variant="outlined"
                                />
                              ) : (
                                <div
                                  style={{
                                    margin: "-28px 0px 0px 12px",
                                    color:
                                      Object.values(lecture)[0]
                                        .toLowerCase()
                                        .includes("break") ||
                                      Object.values(lecture)[0]
                                        .toLowerCase()
                                        .includes("lunch")
                                        ? "black"
                                        : colorList2[colorIndex],
                                    fontSize: "15px",
                                  }}
                                >
                                  {lecture["class"]}
                                </div>
                              )}
                            </div>
                          </Box>
                        </Grid>
                        <Grid md={3}>
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                            }}
                          >
                            {itemIndex == index ? (
                              <Button
                                onClick={() => setItemIndex(9999)}
                                style={{ borderRadius: "20px" }}
                              >
                                <SaveIcon />
                              </Button>
                            ) : (
                              <Button
                                onClick={() => setItemIndex(index)}
                                style={{ borderRadius: "20px" }}
                              >
                                <EditIcon />
                              </Button>
                            )}

                            <Button
                              onClick={() => deleteTimeTableItem(index)}
                              style={{ borderRadius: "20px" }}
                            >
                              <DeleteIcon style={{ color: "#ff3333" }} />
                            </Button>
                          </div>
                        </Grid>
                      </Grid>
                      <br></br>
                    </Fragment>
                  ))}

                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      marginTop: "10px",
                    }}
                  >
                    <Button
                      style={{
                        backgroundColor: "#3283c9",
                        borderRadius: 5,
                        justifyContent: "flex-start",
                        height: "35px",
                        width: "150px",
                      }}
                      onClick={() => addTimeTableItem(selectedDay)}
                    >
                      <AddIcon
                        name="add"
                        style={{ color: "white", fontSize: "30px" }}
                      />
                      <span
                        style={{
                          textAlign: "center",
                          color: "white",
                          marginTop: "1px",
                          marginLeft: "8px",
                          fontFamily: "Bahnschrift",
                          fontSize: "16px",
                        }}
                      >
                        ADD ITEM
                      </span>
                    </Button>
                  </div>
                </Fragment>
              )}
              <br></br>
            </Grid>
            <Grid item md={4} style={{ marginTop: "10px" }}>
              <Box
                borderRadius="6px"
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  width: "100%",
                  border: "2px solid#d7ecff",
                  padding: "10px",
                  marginTop: "15px",
                  alignItems: "center",
                }}
              >
                <Grid container spacing={1.5}>
                  <Grid item md={4}>
                    <Button
                      style={{
                        height: "30px",
                        backgroundColor:
                          selectedDay == "Monday" ? "#3182bd" : "white",
                        boxShadow: "none",
                        width: "100%",
                        fontFamily: "Lato-Regular",
                        borderRadius: "3px",
                        fontWeight:
                          selectedDay == "Monday" ? "bold" : "lighter",
                        color: selectedDay == "Monday" ? "white" : "#3182bd",
                      }}
                      onClick={() => {
                        setSelectedDay("Monday");
                        setItemIndex(9999);
                      }}
                      variant={selectedDay == "Monday" ? "contained" : ""}
                    >
                      Monday
                    </Button>
                  </Grid>
                  <Grid item md={4}>
                    <Button
                      style={{
                        height: "30px",
                        boxShadow: "none",
                        backgroundColor:
                          selectedDay == "Tuesday" ? "#3182bd" : "white",

                        width: "100%",
                        fontFamily: "Lato-Regular",
                        borderRadius: "3px",
                        fontWeight:
                          selectedDay == "Tuesday" ? "bold" : "lighter",
                        color: selectedDay == "Tuesday" ? "white" : "#3182bd",
                      }}
                      onClick={() => {
                        setSelectedDay("Tuesday");
                        setItemIndex(9999);
                      }}
                      variant={selectedDay == "Tuesday" ? "contained" : ""}
                    >
                      Tuesday
                    </Button>
                  </Grid>
                  <Grid item md={4}>
                    <Button
                      style={{
                        height: "30px",
                        boxShadow: "none",
                        backgroundColor:
                          selectedDay == "Wednesday" ? "#3182bd" : "white",
                        width: "100%",
                        fontFamily: "Lato-Regular",
                        borderRadius: "3px",
                        fontWeight:
                          selectedDay == "Wednesday" ? "bold" : "lighter",
                        color: selectedDay == "Wednesday" ? "white" : "#3182bd",
                      }}
                      onClick={() => {
                        setSelectedDay("Wednesday");
                        setItemIndex(9999);
                      }}
                      variant={selectedDay == "Wednesday" ? "contained" : ""}
                    >
                      Wednesday
                    </Button>
                  </Grid>
                  <Grid item md={4}>
                    <Button
                      style={{
                        height: "30px",
                        backgroundColor:
                          selectedDay == "Thursday" ? "#3182bd" : "white",
                        boxShadow: "none",
                        width: "100%",
                        fontFamily: "Lato-Regular",
                        borderRadius: "3px",
                        fontWeight:
                          selectedDay == "Thursday" ? "bold" : "lighter",
                        color: selectedDay == "Thursday" ? "white" : "#3182bd",
                      }}
                      onClick={() => {
                        setSelectedDay("Thursday");
                        setItemIndex(9999);
                      }}
                      variant={selectedDay == "Thursday" ? "contained" : ""}
                    >
                      Thursday
                    </Button>
                  </Grid>{" "}
                  <Grid item md={4}>
                    {" "}
                    <Button
                      style={{
                        height: "30px",
                        boxShadow: "none",
                        backgroundColor:
                          selectedDay == "Friday" ? "#3182bd" : "white",
                        width: "100%",
                        fontFamily: "Lato-Regular",
                        borderRadius: "3px",
                        fontWeight:
                          selectedDay == "Friday" ? "bold" : "lighter",
                        color: selectedDay == "Friday" ? "white" : "#3182bd",
                      }}
                      onClick={() => {
                        setSelectedDay("Friday");
                        setItemIndex(9999);
                      }}
                      variant={selectedDay == "Friday" ? "contained" : ""}
                    >
                      Friday
                    </Button>
                  </Grid>
                  <Grid item md={4}>
                    <Button
                      style={{
                        height: "30px",
                        boxShadow: "none",
                        width: "100%",
                        backgroundColor:
                          selectedDay == "Saturday" ? "#3182bd" : "white",
                        fontFamily: "Lato-Regular",
                        borderRadius: "3px",
                        fontWeight:
                          selectedDay == "Saturday" ? "bold" : "lighter",
                        color: selectedDay == "Saturday" ? "white" : "#3182bd",
                      }}
                      onClick={() => {
                        setSelectedDay("Saturday");
                        setItemIndex(9999);
                      }}
                      variant={selectedDay == "Saturday" ? "contained" : ""}
                    >
                      Saturday
                    </Button>
                  </Grid>
                </Grid>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    marginTop: "10px",
                    width: "34%",
                  }}
                >
                  <Button
                    style={{
                      height: "30px",
                      boxShadow: "none",
                      width: "90%",
                      backgroundColor:
                        selectedDay == "Sunday" ? "#3182bd" : "white",
                      fontFamily: "Lato-Regular",
                      borderRadius: "3px",
                      marginLeft: "6px",
                      fontWeight: selectedDay == "Sunday" ? "bold" : "lighter",
                      color: selectedDay == "Sunday" ? "white" : "#3182bd",
                    }}
                    onClick={() => {
                      setSelectedDay("Sunday");
                      setItemIndex(9999);
                    }}
                    variant={selectedDay == "Sunday" ? "contained" : ""}
                  >
                    Sunday
                  </Button>
                </div>
              </Box>

              <Button
                onClick={() => updateTimeTable()}
                variant="contained"
                style={{
                  width: "100%",
                  marginTop: "15px",
                  height: "35px",
                  backgroundColor: "#3182bd",
                }}
              >
                <h3>Update Timetable</h3>
              </Button>

              {isSubmitting ? (
                <div
                  style={{
                    marginTop: "15px",
                    display: "flex",
                    justifyContent: "center",
                    width: "100%",
                  }}
                >
                  <CircularProgress />
                </div>
              ) : (
                ""
              )}
            </Grid>
          </Grid>
        </div>
      );
    }
  }

  if (!state.auth.isLoading) {
    if (state.auth.isAuthenticated) {
      if (state.auth.user.is_teacher) {
        return (
          <Fragment>
            <Sidebar
              tabs={
                <Grid container spacing={1.5} justifyContent={"flex-end"}>
                  <Grid item md={4}>
                    {selectedPage == "PROFILE" ? (
                      <Button
                        fullWidth
                        value="PROFILE"
                        onClick={handlePageSelect}
                        p={2}
                        style={{
                          backgroundColor: "#3182bd",
                          color: "white",
                          height: "35px",
                        }}
                        borderRadius={6}
                      >
                        <h3
                          style={{
                            marginTop: "17px",
                            fontSize: "15px",
                          }}
                        >
                          PROFILE
                        </h3>
                      </Button>
                    ) : (
                      <Button
                        fullWidth
                        value="PROFILE"
                        onClick={handlePageSelect}
                        p={2}
                        style={{
                          backgroundColor: "#d7ecff",
                          color: "white",
                          height: "35px",
                        }}
                        borderRadius={6}
                      >
                        <h3
                          style={{
                            marginTop: "17px",
                            fontSize: "15px",
                            color: "#084594",
                          }}
                        >
                          PROFILE
                        </h3>
                      </Button>
                    )}
                  </Grid>
                  <Grid item md={4}>
                    {selectedPage == "TIMETABLE" ? (
                      <Button
                        fullWidth
                        value="TIMETABLE"
                        onClick={handlePageSelect}
                        p={2}
                        style={{
                          backgroundColor: "#3182bd",
                          color: "white",
                          height: "35px",
                        }}
                        borderRadius={6}
                      >
                        <h3
                          style={{
                            marginTop: "17px",
                            fontSize: "15px",
                          }}
                        >
                          TIMETABLE
                        </h3>
                      </Button>
                    ) : (
                      <Button
                        fullWidth
                        value="TIMETABLE"
                        onClick={handlePageSelect}
                        p={2}
                        style={{
                          backgroundColor: "#d7ecff",
                          color: "white",
                          height: "35px",
                        }}
                        borderRadius={6}
                      >
                        <h3
                          style={{
                            marginTop: "17px",
                            fontSize: "15px",
                            color: "#084594",
                          }}
                        >
                          TIMETABLE
                        </h3>
                      </Button>
                    )}
                  </Grid>
                </Grid>
              }
            />

            <div
              style={{
                margin: "50px 15px 20px 85px",
                backgroundColor: "white",
              }}
            >
              {selectedPageContent()}
            </div>
          </Fragment>
        );
      } else {
        createAlert("ERROR", "Permission Denied.", "Please Login as Teacher");
        dispatch({
          type: "AUTH_ERROR",
        });
        return <Navigate to="/login" />;
      }
    } else {
      return <Navigate to="/login" />;
    }
  } else {
    return (
      <div
        style={{
          width: "100%",
          height: "100%",
          display: "flex",
          justifyContent: "center",
          backgroundColor: "white",
          marginTop: "46vh",
        }}
      >
        <CircularProgress />
      </div>
    );
  }
}

export default Settings;
